import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('bookings', { params }).then(({ data }) => {
        setPagination(() => data.result?.bookings?.pagination || {});
        return data.result?.bookings?.data || [];
      })
  });

  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.center')}</th>
            <th>{t('form.doctor')}</th>
            <th>{t('form.patient')}</th>
            <th>{t('form.advance-payment')}</th>
            <th>{t('total')}</th>
            <th>{t('payment-status')}</th>
            <th>{t('status')}</th>
            <th>{t('form.booking-time')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>{item.center?.name}</td>
                <td>
                  <p>{item.doctor?.name}</p>
                  <p>{item.doctor?.mobile}</p>
                </td>
                <td>
                  <p>{item.patient?.name}</p>
                  <p>{item.patient?.mobile}</p>
                </td>
                <td>{CurrencyFormatter(item.advance_payment || 0)}</td>
                <td>{CurrencyFormatter(item.total || 0)}</td>
                <td>{item.is_paid ? t('statuses.paid') : t('statuses.not-paid')}</td>
                <td>{t('statuses.' + item.status)}</td>
                <td>{item.booking_time}</td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <Link
                      to={'/bookings/' + item.id}
                      className="edit-btn"
                    >
                      <span>{t('view')}</span>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <Link
            to="/bookings/add"
            className="btn-with-icon !text-gray-600 bg-gray-100"
          >
            <span>{t('add-new')}</span>
          </Link>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

