import { Tab } from '@headlessui/react';
import { serialize } from 'object-to-formdata';
import React, { ChangeEvent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Card from 'src/components/shared/Card';
import DisplayGallery from 'src/components/shared/DisplayGallery';
import Editor from 'src/components/shared/Editor';
import FileUploader from 'src/components/shared/FileUploader';
import Select from 'src/components/shared/Select';
import AssignCenterCategories from 'src/components/shared/centers/AssignCenterCategories';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

const tabs = ['overflow', 'pages.categories'];
export default function Update() {
  const { t } = useTranslation();

  return (
    <div className="p-6">
      <Tab.Group>
        <Tab.List className="flex items-center whitespace-nowrap overflow-x-auto">
          {tabs.map((tab, index) => (
            <Tab
              as={Fragment}
              key={index}
            >
              {({ selected }) => (
                <button
                  className={`btn-with-icon transition ${
                    selected ? 'bg-primary' : '!text-gray-600'
                  }`}
                >
                  <span>{t(tab)}</span>
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="pt-6">
              <UpdateBody />
            </div>
          </Tab.Panel>

          <Tab.Panel>
            <div className="pt-6">
              <AssignCenterCategories />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

function UpdateBody() {
  let rerender = true;
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [features, setFeatures] = React.useState<any[]>([]);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [districts, setDistricts] = React.useState<any[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();

  React.useEffect(() => {
    if (rerender) {
      Promise.all([GetSelectedCenter(), GetFeatures()]).finally(() => setIsLoading(false));
      rerender = false;
    }
  }, []);

  function GetSelectedCenter() {
    prepareRequest({ url: 'centers/' + id }, (data) => {
      let center = data.result?.center || {};
      if (!center.is_active) return goBack();

      center.features = center.features?.map((item: any) => item.id);
      center.region_id = center.region?.id;
      center.city_id = center.city?.id;
      center.district_id = center.district?.id;
      setInitialValues(() => center);
    });
  }

  function GetFeatures() {
    prepareRequest({ url: 'features', params: { is_active: 1 } }, (data) => {
      if (data) setFeatures(() => data.result?.features || []);
    });
  }

  const goBack = React.useCallback(function () {
    navigate('/settings/centers', { replace: true });
  }, []);

  const { formik, handleChange } = useForm({
    initialValues: {
      ...initialValues,
      images: []
    },
    submitHandler: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        { url: 'centers/update', method: 'post', data: serialize(values, { indices: true }) },
        (data, error) => {
          if (error) return setErrors(() => error);
          helper.resetForm();
          navigate('/settings/centers', { replace: true });
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-4">
      <form
        className="space-y-4"
        onSubmit={formik.handleSubmit}
      >
        <Card className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('form.name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.name}
                onChange={(e) => handleChange('name', e)}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('form.alt-name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.alt_name}
                onChange={(e) => handleChange('alt_name', e)}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>

            <div className="form-group">
              <label className="form-label">{t('form.email')}</label>
              <input
                type="email"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.email}
                onChange={(e) => handleChange('email', e)}
              />
              {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('form.mobile')}</label>
              <input
                type="tel"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.mobile}
                onChange={(e) => handleChange('mobile', e)}
              />
              {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('form.vat-no')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.vat_no}
                onChange={(e) => handleChange('vat_no', e)}
              />
              {errors?.vat_no ? <span className="form-error">{errors?.vat_no}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('form.cr-no')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.cr_no}
                onChange={(e) => handleChange('cr_no', e)}
              />
              {errors?.cr_no ? <span className="form-error">{errors?.cr_no}</span> : null}
            </div>
          </div>

          <div className="form-group">
            <label className="form-label">{t('form.features')}</label>
            <Select
              type={'multi'}
              options={features}
              value={formik.values.feature_ids}
              onSelect={function (value: any): void {
                console.log(value);
                formik.setFieldValue('feature_ids', value);
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />

            {errors?.feature_ids ? <span className="form-error">{errors?.feature_ids}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.about')}</label>

            <Editor
              onChange={(value) => formik.setFieldValue('info', value)}
              value={formik.values.info}
            />
            {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.alt-about')}</label>
            <Editor
              onChange={(value) => formik.setFieldValue('alt_info', value)}
              value={formik.values.alt_info}
            />
            {errors?.alt_info ? <span className="form-error">{errors?.alt_info}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('offer-payment-details')}</label>
            <textarea
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.offer_payment_details}
              onChange={(e) => handleChange('offer_payment_details', e)}
            ></textarea>
            {errors?.offer_payment_details ? (
              <span className="form-error">{errors?.offer_payment_details}</span>
            ) : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('alt-offer-payment-details')}</label>
            <textarea
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.alt_offer_payment_details}
              onChange={(e) => handleChange('alt_offer_payment_details', e)}
            ></textarea>
            {errors?.alt_offer_payment_details ? (
              <span className="form-error">{errors?.alt_offer_payment_details}</span>
            ) : null}
          </div>
          <div className="form-group">
            <FileUploader
              name="images"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue('images', event.target.files);
              }}
              accept="image/*"
              multiple
            />
            <p className="form-error">{errors?.images}</p>
          </div>
        </Card>
        <div>
          <div className="flex gap-3 flex-wrap">
            <Button
              disabled={disabled}
              type="submit"
              variant="primary"
            >
              {t('submit')}
            </Button>
            <Button
              disabled={disabled}
              type="reset"
              onClick={goBack}
            >
              {t('back')}
            </Button>
          </div>
        </div>
      </form>

      {!!initialValues.images?.length && (
        <DisplayGallery
          items={initialValues.images || []}
          pathname="centers/delete_image"
          refetch={GetSelectedCenter}
        />
      )}
    </div>
  );
}
