import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';

function CenterCard() {
  const { t } = useTranslation();
  const [centers, setCenters] = useState<any[]>([]);
  const [center, setCenter] = useState<string>(localStorage.getItem('@center') || ('' as string));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getCenters();
  }, []);

  async function getCenters() {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get('centers', { params: { is_active: 1 } });
      setCenters(() => data.result?.centers || []);
      localStorage.setItem('@center', data.result?.centers[0]?.id || '');
      localStorage.setItem('@center_name', data.result?.centers[0]?.name || '');
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="w-full h-8 rounded bg-gray-200 animate-pulse transition"></div>
      ) : centers.length > 1 ? (
        <select
          name="center"
          id="center"
          className="form-select form-outline w-full"
          defaultValue={center}
          onChange={(ev: ChangeEvent<HTMLSelectElement>) => {
            setCenter(() => ev.target.value);
            localStorage.setItem('@center', ev.target.value);
            localStorage.setItem(
              '@center_name',
              centers.find((c: any) => c.id == ev.target.value)?.name || ''
            );
          }}
        >
          <option value="">-- {t('select')} --</option>
          {centers.map((center: any) => (
            <option
              key={center.id}
              value={center.id}
            >
              {center.name}
            </option>
          ))}
        </select>
      ) : (
        <p className="text-gray-600">{centers[0]?.name || ''}</p>
      )}
    </>
  );
}

export default CenterCard;

