import { Icon } from '@iconify/react';
import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import generateFileSize from 'src/helper/generateFileSize';

export default function FileUploader({ onChange, ...rest }: InputHTMLAttributes<HTMLInputElement>) {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[] | null>(null);
  const uploader = useRef<HTMLInputElement | null>(null);

  function onFilesChanged(event: ChangeEvent<HTMLInputElement>) {
    const attachments = event.target.files;
    const element = uploader.current;

    // return info alert in case no files selected
    if (!attachments?.length) return generateAlert(t('no-selected-files'), 'info');

    // setFiles(() => attachments);

    setFiles((prev) => [...attachments]);
    onChange?.(event);
    // if (element) {
    //   element.value = '';
    // }
  }

  return (
    <div className="space-y-3">
      <label
        htmlFor="file-uploader"
        className="w-full h-auto flex flex-col p-6 text-center items-center justify-center space-y-3 font-medium bg-gray-50 border-2 border-gray-200 border-dashed transition-all duration-150 group rounded-lg cursor-pointer"
      >
        <Icon
          icon="solar:cloud-upload-bold-duotone"
          width="3rem"
          height="3rem"
          className="text-gray-700"
        />
        <p className="text-center text-sm max-w-screen-sm mx-auto text-gray-500 font-medium">
          {t('file-uploader-content')}
        </p>
        <input
          type="file"
          id="file-uploader"
          hidden
          className="hidden"
          ref={uploader}
          onChange={onFilesChanged}
          {...rest}
        />
      </label>

      {!!files?.length && (
        <ul className="flex items-center gap-3 flex-wrap">
          {files?.map((file: File, index: number) => (
            <li key={index}>
              <div className="flex items-center gap-3 p-4 rounded bg-gray-100">
                <div>
                  <Icon
                    icon="solar:cloud-file-bold-duotone"
                    width="2rem"
                    height="2rem"
                    className="text-blue-700"
                  />
                </div>
                <div className="flex-1">
                  <div className="grid">
                    <p className="text-sm font-semibold line-clamp-1">{file.name}</p>
                  </div>
                  <p className="text-xs text-gray-500">{generateFileSize(file.size)}</p>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setFiles(() => files?.filter((_file, idx: number) => idx !== index));
                      if (uploader.current) uploader.current.value = '';
                    }}
                  >
                    <Icon
                      icon="solar:trash-bin-trash-bold-duotone"
                      width="1.5rem"
                      height="1.5rem"
                      className="text-red-700"
                    />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
