import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SharedTime from 'src/components/shared/SharedTime';
import AddForm from 'src/components/shared/roles/AddForm';
import UpdateForm from 'src/components/shared/roles/UpdateForm';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import usePermissions from 'src/hooks/usePermissions';

export default function Patients() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });
  const { hasAccess } = usePermissions();
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('roles', { params }).then(({ data }) => {
        setPagination(() => data.result?.roles?.pagination || {});
        return data.result?.roles?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  {item.name} - {item.alt_name}
                </td>

                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateForm
                      refetch={refetch}
                      item={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            <AddForm refetch={refetch} />
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}
