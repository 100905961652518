import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import AddForm from 'src/components/shared/users/AddForm';
import UpdateForm from 'src/components/shared/users/UpdateForm';
import axiosInstance from 'src/helper/AxiosInstance';
import usePermissions from 'src/hooks/usePermissions';

export default function Patients() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1', user_type: 'patient' });
  const { hasAccess } = usePermissions();
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('users', { params }).then(({ data }) => {
        setPagination(() => data.result?.users?.pagination || {});
        return data.result?.users?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('form.email')}</th>
            <th>{t('form.mobile')}</th>
            <th>{t('form.gender')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.email || '-'}</td>
                <td>{item.mobile || '-'}</td>
                <td>{t(item.gender) || '-'}</td>

                <td>
                  <ChangeStatus
                    pathname={'users/update_is_active/' + item.id}
                    refetch={refetch}
                    active={!!item.is_active}
                  />
                </td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateForm
                      item={item}
                      refetch={refetch}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            <AddForm
              user_type="patient"
              refetch={refetch}
            />
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}
