import { serialize } from 'object-to-formdata';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Button from '../Button';
import Card from '../Card';
import Editor from '../Editor';
import FileUploader from '../FileUploader';
import Mobile from '../Mobile';
import Select from '../Select';

export default function UpdateDoctorForm({ item, refetch }: { item: any; refetch?: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any[]>([]);
  const [centers, setCenters] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    getCenters();
    getCategories();
  }, [item]);

  function getCenters() {
    prepareRequest(
      {
        url: 'centers',
        params: { is_active: 1 }
      },
      (data) => {
        if (data) setCenters(() => data.result?.centers || []);
      }
    );
  }
  function getCategories() {
    const center_id = item.centers?.map((center: any) => center.id);

    prepareRequest(
      {
        url: 'categories',
        params: { is_active: 1, center_id }
      },
      (data) => {
        if (data) setCategories(() => data.result?.categories || []);
      }
    );
  }

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({
    initialValues: {
      ...item,
      images: []
    },
    submitHandler: (values, helper) => {
      setDisabled(true);
      setErrors({});

      const fd = serialize(values, {
        indices: true
      });

      console.log(values);

      fd.append('mobile', values.mobile?.replace(/\s/gi, ''));

      prepareRequest(
        {
          url: 'users/update',
          method: 'post',
          data: fd
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          helper.resetForm();
          refetch?.();
          window.location.reload();
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <Card>
      <form
        className="space-y-4"
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <p className="form-label">{t('form.name')}</p>
          <input
            type="text"
            className="form-input form-outline"
            value={values.name}
            name="name"
            onChange={handleChange}
            placeholder="..."
          />
          <p className="form-error">{errors.name}</p>
        </div>
        <div className="form-group">
          <p className="form-label">{t('form.email')}</p>
          <input
            type="email"
            className="form-input form-outline"
            value={values.email}
            name="email"
            onChange={handleChange}
            placeholder="example@example.com"
          />
          <p className="form-error">{errors.email}</p>
        </div>
        <div className="form-group">
          <p className="form-label">{t('form.mobile')}</p>
          <Mobile
            className="form-input form-outline"
            value={values.mobile}
            name="mobile"
            onChange={handleChange}
          />
          <p className="form-error">{errors.mobile}</p>
        </div>
        <div className="form-group col-span-full">
          <p className="form-label">{t('form.price')}</p>
          <input
            type="number"
            className="form-input form-outline"
            value={values.price}
            name="price"
            onChange={handleChange}
            placeholder="0.00"
            step="any"
          />
          <p className="form-error">{errors.price}</p>
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-group">
            <p className="form-label">{t('form.qualification')}</p>
            <input
              type="text"
              className="form-input form-outline"
              value={values.qualification}
              name="qualification"
              onChange={handleChange}
              placeholder="..."
            />
            <p className="form-error">{errors.qualification}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-qualification')}</p>
            <input
              type="text"
              className="form-input form-outline"
              value={values.alt_qualification}
              name="alt_qualification"
              onChange={handleChange}
              placeholder="..."
            />
            <p className="form-error">{errors.alt_qualification}</p>
          </div>

          
          <div className="form-group">
            <p className="form-label">{t('form.advance-payment')}</p>
            <input
              type="number"
              className="form-input form-outline"
              value={values.advance_payment_value}
              name="advance_payment_value"
              onChange={handleChange}
              placeholder="0.00"
              step="any"
            />
            <p className="form-error">{errors.advance_payment_value}</p>
          </div>
          <div className="form-group">
            <p className="form-label">
              {t('form.advance-payment')} {t('percentage')}
            </p>
            <input
              type="number"
              className="form-input form-outline"
              value={values.advance_payment_percentage}
              name="advance_payment_percentage"
              onChange={handleChange}
              placeholder="00.0"
              step="any"
            />
            <p className="form-error">{errors.advance_payment_percentage}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.duration')}</p>
            <input
              type="number"
              className="form-input form-outline"
              value={values.duration}
              name="duration"
              onChange={handleChange}
              placeholder="00"
              step="01"
            />
            <p className="form-error">{errors.duration}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.break-time')}</p>
            <input
              type="number"
              className="form-input form-outline"
              value={values.breaktime}
              name="breaktime"
              onChange={handleChange}
              placeholder="00"
              step="01"
            />
            <p className="form-error">{errors.breaktime}</p>
          </div>
          <div className="form-group col-span-full">
            <p className="form-label">{t('form.age')}</p>
            <input
              type="number"
              className="form-input form-outline"
              value={values.age}
              name="age"
              onChange={handleChange}
              placeholder="0"
            />
            <p className="form-error">{errors.age}</p>
          </div>
        </div> */}
        <div className="form-group">
          <label className="form-label">{t('form.info')}</label>
          <Editor
            onChange={(value) => setFieldValue('info', value)}
            value={values.info}
          />
          {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('form.alt-info')}</label>
          <Editor
            onChange={(value) => setFieldValue('alt_info', value)}
            value={values.alt_info}
          />
          {errors?.alt_info ? <span className="form-error">{errors?.alt_info}</span> : null}
        </div>
        <div className="form-group">
          <p className="form-label">{t('form.centers')}</p>
          <Select
            type="multi"
            options={centers}
            optionTxt="name"
            optionValue="id"
            value={values.center_ids}
            onSelect={(value) => setFieldValue('center_ids', value)}
          />
          <p className="form-error">{errors.center_ids}</p>
        </div>
        <div className="form-group">
          <p className="form-label">{t('form.categories')}</p>
          <Select
            type="multi"
            options={categories}
            optionTxt="name"
            optionValue="id"
            value={values.category_ids}
            onSelect={(value) => setFieldValue('category_ids', value)}
          />
          <p className="form-error">{errors.category_ids}</p>
        </div>
        {/* <div className="form-group">
          <p className="form-label">{t('form.password')}</p>
          <Password
            className="form-input form-outline"
            value={values.password}
            name="password"
            onChange={handleChange}
          />
          <p className="form-error">{errors.password}</p>
        </div> */}

        <div className="form-group">
          <p className="form-label">{t('form.gender')}</p>
          <div className="flex flex-wrap gap-4">
            <div className="flex gap-4 items-center flex-1 shrink-0">
              <input
                type="radio"
                name="gender"
                id="male"
                value="male"
                checked={values.gender === 'male'}
                onChange={handleChange}
                className="from-radio shrink-0"
              />
              <label
                htmlFor="male"
                className="cursor-pointer form-label"
              >
                {t('male')}
              </label>
            </div>
            <div className="flex gap-4 items-center flex-1 shrink-0">
              <input
                type="radio"
                name="gender"
                id="female"
                value="female"
                checked={values.gender === 'female'}
                onChange={handleChange}
                className="from-radio shrink-0"
              />
              <label
                htmlFor="female"
                className="cursor-pointer form-label"
              >
                {t('female')}
              </label>
            </div>
          </div>
          <p className="form-error">{errors.password}</p>
        </div>
        <div className="form-group">
          <p className="form-label">{t('form.images')}</p>
          <FileUploader
            name="images"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('images', event.target.files);
            }}
            accept="image/*"
            multiple
          />
          <p className="form-error">{errors.images}</p>
        </div>

        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          {t('save-changes')}
        </Button>
      </form>
    </Card>
  );
}
