import { serialize } from 'object-to-formdata';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Editor from 'src/components/shared/Editor';
import FileUploader from 'src/components/shared/FileUploader';
import Select from 'src/components/shared/Select';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
// import GoogleMap from '../../components/shared/GoogleMap';

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  info: string | undefined;
  alt_info: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  region_id: string | undefined;
  city_id: string | undefined;
  district_id: string | undefined;
  owner_id: string | undefined;
  package_id: string | undefined;
  address: string | undefined;
  // lat: number | undefined;
  // lng: number | undefined;
  feature_ids: string[] | undefined;
  images: File[] | undefined;
}

export default function Create() {
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    info: undefined,
    alt_info: undefined,
    email: undefined,
    mobile: undefined,
    region_id: undefined,
    city_id: undefined,
    district_id: undefined,
    owner_id: undefined,
    package_id: undefined,
    address: undefined,
    // lat: 24.694969,
    // lng: 46.724129,
    feature_ids: [],
    images: []
  } satisfies FormInterface;
  let rerender = true;
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [features, setFeatures] = React.useState<any[]>([]);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [districts, setDistricts] = React.useState<any[]>([]);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (rerender) {
      Promise.all([GetFeatures(), getRegions()]).finally(() => setIsLoading(false));
      rerender = false;
    }
  }, []);

  function GetFeatures() {
    prepareRequest({ url: 'features', params: { is_active: 1 } }, (data) => {
      if (data) setFeatures(() => data.result?.features || []);
    });
  }

  function getRegions() {
    prepareRequest({ url: 'regions', params: { is_active: 1 } }, (data) => {
      if (data) setRegions(() => data.result?.regions || []);
    });
  }

  const goBack = React.useCallback(function () {
    navigate('/settings/centers', { replace: true });
  }, []);

  const { formik, handleChange } = useForm({
    initialValues,
    submitHandler: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        { url: 'centers/add', method: 'post', data: serialize(values, { indices: true }) },
        (data, error) => {
          if (error) return setErrors(() => error);
          helper.resetForm();
          navigate('/settings/centers', { replace: true });
        }
      ).finally(() => setDisabled(false));
    }
  });

  function getCities(q?: string, region_id?: any) {
    prepareRequest(
      {
        url: 'cities',
        params: { is_active: 1, page: 1, search_key: q, region_id }
      },
      (data) => {
        if (data) setCities(() => data.result?.cities?.data || []);
      }
    );
  }

  function getDistricts(q?: string, region_id?: any, city_id?: any) {
    prepareRequest(
      {
        url: 'districts',
        params: {
          is_active: 1,
          page: 1,
          search_key: q,
          region_id,
          city_id
        }
      },
      (data) => {
        if (data) setDistricts(() => data.result?.districts?.data || []);
      }
    );
  }

  return (
    <React.Fragment>
      <div className="p-6">
        <form
          className="grid grid-cols-1 xl:grid-cols-2 gap-4 items-start"
          onSubmit={formik.handleSubmit}
        >
          <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
            <figcaption className="px-6 py-4 border-b border-b-gray-200">
              <p className="text-sm font-semibold text-gray-500">{t('basic-info')}</p>
            </figcaption>
            <blockquote className="space-y-4 p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="form-group">
                  <label className="form-label">{t('form.name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.name}
                    onChange={(e) => handleChange('name', e)}
                  />
                  {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('form.alt-name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.alt_name}
                    onChange={(e) => handleChange('alt_name', e)}
                  />
                  {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
                </div>
              </div>

              <div className="form-group">
                <label className="form-label">{t('form.email')}</label>
                <input
                  type="email"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.email}
                  onChange={(e) => handleChange('email', e)}
                />
                {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('form.mobile')}</label>
                <input
                  type="tel"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.mobile}
                  onChange={(e) => handleChange('mobile', e)}
                />
                {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
              </div>
              {/* <div className="form-group">
                <label className="form-label">Package</label>
                <Select
                  type={'single'}
                  options={packages}
                  value={formik.values.package_id}
                  onSelect={function (value: any): void {
                    formik.setFieldValue('package_id', value);
                  }}
                  optionTxt={'name'}
                  optionValue={'id'}
                />

                {errors?.package_id ? (
                  <span className="form-error">{errors?.package_id}</span>
                ) : null}
              </div> */}
              <div className="form-group">
                <label className="form-label">{t('form.features')}</label>
                <Select
                  type={'multi'}
                  options={features}
                  value={formik.values.feature_ids}
                  onSelect={function (value: any): void {
                    console.log(value);
                    formik.setFieldValue('feature_ids', value);
                  }}
                  optionTxt={'name'}
                  optionValue={'id'}
                />

                {errors?.feature_ids ? (
                  <span className="form-error">{errors?.feature_ids}</span>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('form.about')}</label>
                {/* <textarea
                  name="info-body"
                  id="info-body"
                  placeholder=".."
                  onChange={(e) => handleChange('info', e)}
                  value={formik.values.info}
                  className="form-textarea form-outline"
                ></textarea> */}

                <Editor
                  onChange={(value) => formik.setFieldValue('info', value)}
                  value={formik.values.info}
                />
                {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('form.alt-about')}</label>
                <Editor
                  onChange={(value) => formik.setFieldValue('alt_info', value)}
                  value={formik.values.alt_info}
                />
                {errors?.alt_info ? <span className="form-error">{errors?.alt_info}</span> : null}
              </div>
            </blockquote>
          </figure>
          <div className="space-y-4">
            <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
              <figcaption className="px-6 py-4 border-b border-b-gray-200">
                <p className="text-sm font-semibold text-gray-500">{t('location')}</p>
              </figcaption>
              <blockquote className="space-y-4 p-6">
                <div className="form-group">
                  <label className="form-label">{t('form.region')}</label>
                  <Select
                    type={'single'}
                    options={regions}
                    value={formik.values.region_id}
                    onSelect={function (value: any): void {
                      formik.setFieldValue('region_id', value);
                      formik.setFieldValue('city_id', undefined);
                      formik.setFieldValue('district_id', undefined);
                      getCities(undefined, value);
                    }}
                    optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
                    optionValue={'id'}
                  />

                  {errors?.region_id ? (
                    <span className="form-error">{errors?.region_id}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('form.city')}</label>
                  <Select
                    type={'single'}
                    options={cities}
                    value={formik.values.city_id}
                    onSelect={function (value: any): void {
                      formik.setFieldValue('city_id', value);
                      formik.setFieldValue('district_id', undefined);
                      getDistricts(undefined, formik.values.region_id, value);
                    }}
                    onSearchChange={(v) => getCities(v, formik.values.region_id)}
                    optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
                    optionValue={'id'}
                  />

                  {errors?.city_id ? <span className="form-error">{errors?.city_id}</span> : null}
                </div>
                <div className="form-group col-span-full">
                  <label className="form-label">{t('form.district')}</label>
                  <Select
                    type={'single'}
                    options={districts}
                    value={formik.values.district_id}
                    onSelect={function (value: any): void {
                      formik.setFieldValue('district_id', value);
                    }}
                    onSearchChange={(v) =>
                      getDistricts(v, formik.values.region_id, formik.values.city_id)
                    }
                    optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
                    optionValue={'id'}
                  />

                  {errors?.district_id ? (
                    <span className="form-error">{errors?.district_id}</span>
                  ) : null}
                </div>
                {/* <Map
                  onChange={function (position: {
                    lat: number | undefined;
                    lng: number | undefined;
                    address: string | undefined;
                  }): void {
                    formik.setFieldValue('lat', position?.lat);
                    formik.setFieldValue('lng', position?.lng);
                    formik.setFieldValue('address', position?.address);
                  }}
                /> */}
              </blockquote>
            </figure>
            <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
              <figcaption className="px-6 py-4 border-b border-b-gray-200">
                <p className="text-sm font-semibold text-gray-500">{t('form.images')}</p>
              </figcaption>
              <blockquote className="space-y-4 p-6">
                <div className="form-group">
                  <FileUploader
                    name="images"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('images', event.target.files);
                    }}
                    accept="image/*"
                    multiple
                  />
                  <p className="form-error">{errors?.images}</p>
                </div>
              </blockquote>
            </figure>
            {/* <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
            <blockquote className="space-y-4 p-6">
              <GoogleMap
                lat={formik.values.lat}
                lng={formik.values.lng}
                onChange={(val: any) => {
                  console.log(val);
                }}
              />
            </blockquote>
          </figure> */}
            <div>
              <div className="flex gap-3 flex-wrap">
                <Button
                  disabled={disabled}
                  type="submit"
                  variant="primary"
                >
                  {t('submit')}
                </Button>
                <Button
                  disabled={disabled}
                  type="reset"
                  onClick={goBack}
                >
                  {t('back')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
