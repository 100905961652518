import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import useLogout from 'src/hooks/useLogout';
import usePermissions from 'src/hooks/usePermissions';
import { RootState } from 'src/store';
import { MoveToBottom, MoveToTop } from '../animations';
import CenterCard from 'src/components/shared/centers/CenterCard';

interface RouterInterface {
  title: string;
  path: string;
  icon: string;
  permission?: string | string[];
}

const routes: RouterInterface[] = [
  {
    title: 'dashboard',
    permission: 'dashboard',
    icon: 'ri:home-5-line',
    path: '/'
  },

  {
    title: 'bookings',
    permission: 'bookings',
    icon: 'uil:invoice',
    path: '/bookings'
  },
  {
    title: 'doctors',
    permission: 'doctors',
    icon: 'maki:doctor',
    path: '/doctors'
  },
  {
    title: 'patients',
    permission: 'patients',
    icon: 'mdi:patient-outline',
    path: '/patients'
  },

  {
    icon: 'uil:invoice',
    title: 'invoices',
    permission: 'invoices',
    path: '/invoices'
  },

  {
    title: 'offers',
    permission: 'offers',
    icon: 'heroicons:hashtag-solid',
    path: '/offers'
  },

  {
    title: 'settings',
    icon: 'ri:settings-line',
    path: '/settings'
  }
];

const SideBar: FC = () => {
  const location = useLocation();
  const menuContainer = useRef<HTMLElement | null>(null);
  const { user, token } = useSelector((state: RootState) => state.auth);
  const { handleLogged } = useLogout();
  const { t } = useTranslation();

  const { hasPermissions } = usePermissions();

  const center_name = localStorage.getItem('@center_name');

  const openCloseMenu = useCallback(function (ev: React.MouseEvent) {
    const currentTarget = ev.target as HTMLElement | null;
    if (currentTarget === menuContainer?.current) {
      menuContainer?.current?.classList.add('hidden');
    }
  }, []);

  const closeMenu = useCallback(function () {
    menuContainer?.current?.classList.add('hidden');
  }, []);

  return (
    <aside
      className="w-full xl:max-w-[18rem] shrink-0 bg-gray-800/20 shadow-2xl shadow-gray-600/5 fixed xl:relative z-20 hidden xl:block"
      ref={menuContainer}
      data-type="menu"
      onClick={openCloseMenu}
    >
      <div className="w-full max-w-[18rem] py-0 px-4 bg-gray-100 sticky top-0 min-h-screen max-h-screen overflow-y-auto flex flex-col border-r rtl:border-r-none rtl:border-l border-gray-200">
        <div className="w-full flex items-center justify-between py-4">
          <div>
            <Link
              to="/"
              className="shrink-0"
            >
              <img
                src="/images/black-logo.svg"
                alt="website logo"
                className="w-full max-w-[8rem] "
              />
            </Link>
          </div>
          <button
            className="shrink-0 text-gray-500 flex xl:hidden items-center justify-center"
            onClick={closeMenu}
          >
            <Icon
              icon="line-md:menu-to-close-transition"
              width={18}
            />
          </button>
        </div>

        <div className="py-4 border-y border-y-gray-200 mb-4 flex gap-2 text-sm flex-wrap">
          <span className="text-gray-600 font-bold">{t('form.center')}</span>
          <CenterCard />
        </div>

        <nav className="flex-1">
          <ul className="space-y-1">
            {routes.filter(hasPermissions).map((route: RouterInterface, index: string | number) => (
              <motion.li
                key={index}
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
              >
                <Link
                  to={route.path}
                  className={[
                    'w-full flex items-center gap-4 text-base py-2 px-3 rounded ',
                    new RegExp('^' + route.path + '$', 'gi').test(location.pathname)
                      ? 'bg-primary/5 text-primary font-semibold'
                      : 'text-gray-500'
                  ].join(' ')}
                >
                  <span className="shrink-0">
                    <Icon
                      icon={route.icon}
                      width={20}
                    />
                  </span>
                  <span>{t(`pages.${route.title}`)}</span>
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>
        <div className="grid">
          <div className="w-full border-t border-t-gray-100 flex items-center gap-4 py-4">
            <Link
              to="/account/profile"
              className="shrink-0 flex gap-4 flex-1"
            >
              <Image
                src={user?.image}
                className="shrink-0 w-10 h-10 rounded-full"
              />
              <div className="flex-1 grid">
                <motion.p
                  animate="visible"
                  initial="hidden"
                  variants={MoveToTop}
                  className="text-base font-bold text-gray-800 tabular-nums truncate"
                >
                  {user?.name || 'UNKNOWN'}
                </motion.p>

                <motion.a
                  animate="visible"
                  initial="hidden"
                  variants={MoveToBottom}
                  className="text-sm font-medium text-gray-500 truncate"
                >
                  {user?.email || 'UNKNOWN'}
                </motion.a>
              </div>
            </Link>
            <button
              className="shrink-0  text-gray-500 flex items-center justify-center"
              onClick={handleLogged}
            >
              <Icon
                icon="heroicons-outline:logout"
                width={24}
              />
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;

