import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('centers', { params }).then(({ data }) => {
        setPagination(() => data.result?.centers?.pagination || {});
        return data.result?.centers?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('form.package')}</th>
            <th>{t('form.country')}</th>
            <th>{t('form.region')}</th>
            <th>{t('form.district')}</th>
            <th>{t('form.address')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((center: any) => (
              <tr key={center.id}>
                <td>
                  {center.name} - {center.alt_name}
                </td>
                <td>{center.package?.name || '-'}</td>
                <td>{center.country?.name || '-'}</td>
                <td>{center.region?.name || '-'}</td>
                <td>{center.district?.name || '-'}</td>
                <td>{center.address || '-'}</td>
                <td>
                  <ChangeStatus
                    pathname={'centers/update_is_active/' + center.id}
                    refetch={refetch}
                    active={!!center.is_active}
                  />
                </td>
                <td>
                  <SharedTime date={center.created_at} />
                </td>
                <td>
                  <div className="actions">
                    {!!center.is_active && (
                      <Link
                        to={'/settings/centers/' + center.id}
                        className="edit-btn"
                      >
                        <span>{t('edit')}</span>
                      </Link>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            <Link
              to="/settings/centers/create"
              className="btn-with-icon !text-gray-600 bg-gray-100"
            >
              <span>{t('add-new')}</span>
            </Link>
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}
