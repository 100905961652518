import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function LoadingChip() {
  const { t } = useTranslation();
  return (
    <p className="w-fit flex items-center gap-2 mx-auto py-2 px-3 rounded-full bg-gray-200 text-gray-600 font-semibold">
      <span>{t('loading')}</span>
      <Icon
        icon="eos-icons:loading"
        width="18"
        height="18"
      />
    </p>
  );
}
