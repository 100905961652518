import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Button from '../Button';
import Switcher from '../Switcher';

export default function DisplayWorkHour({
  item,
  center,
  shift_from,
  shift_to,
  refetch
}: {
  item: any;
  center: string;
  shift_from: string;
  shift_to: string;
  refetch: () => any;
}) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const [errors, setErrors] = useState<any>({});
  const { t } = useTranslation();

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({
    initialValues: {
      center_id: center,
      doctor_id: id,
      shift_from,
      shift_to,
      day: item.id
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'working_hours/add',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch();
        }
      ).finally(() => setDisabled(false));
    }
  });

  const isDisabled = useMemo(
    () => !!(center && (values.shift_from || values.shift_to)),
    [values, center]
  );

  return (
    <tr>
      <td>
        <Switcher
          checked={isDisabled}
          onChange={(v) => {
            const selected = !!(values.shift_from || values.shift_to);
            if (v) {
              if (selected) {
                setFieldValue('shift_from', '');
                setFieldValue('shift_to', '');
              } else {
                setFieldValue('shift_from', moment().format('hh:mm'));
                setFieldValue('shift_to', moment().add(6, 'hours').format('hh:mm'));
              }
            } else {
              setFieldValue('shift_from', '');
              setFieldValue('shift_to', '');
            }
          }}
        />
      </td>
      <td>{item.day}</td>
      <td>
        <div className="form-group">
          <input
            type="time"
            name="shift_from"
            id="shift_from"
            defaultValue={values.shift_from}
            value={values.shift_from}
            onChange={handleChange}
            className="form-input"
            disabled={!isDisabled}
          />
          <p className="form-error">{errors['shift_from']}</p>
        </div>
      </td>
      <td>
        <div className="form-group">
          <input
            type="time"
            name="shift_to"
            id="shift_to"
            defaultValue={values.shift_to}
            value={values.shift_to}
            onChange={handleChange}
            className="form-input"
            disabled={!isDisabled}
          />
          <p className="form-error">{errors['shift_to']}</p>
        </div>
      </td>
      <td>
        {isDisabled && (
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              className="!max-w-fit"
              disabled={disabled}
              variant="primary"
            >
              <span>{t('save-changes')}</span>
            </Button>
          </form>
        )}
      </td>
    </tr>
  );
}
