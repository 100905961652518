import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from 'src/hooks/usePermissions';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../../components/shared/MiniDashboardSingleCard';

const Settings: FC = () => {
  // const { user } = useSelector((state: RootState) => state.auth);
  const { hasPermissions } = usePermissions();
  const { t } = useTranslation();

  const generalSettings: ItemInterface[] = [
    {
      value: t('pages.categories'),
      icon: 'la:cubes',
      url: '/settings/categories',
      permission: 'categories'
    },
    {
      value: t('pages.services'),
      icon: 'ph:stack',
      url: '/settings/services',
      permission: 'services'
    },

    {
      value: t('pages.centers'),
      icon: 'ri:hospital-line',
      url: '/settings/centers',
      permission: 'centers'
    },
    {
      value: t('pages.users'),
      icon: 'ci:users',

      url: '/settings/users'
    },
    {
      value: t('pages.prices'),
      icon: 'solar:dollar-outline',
      url: '/settings/prices'
    },
    {
      value: t('pages.packages'),
      icon: 'solar:tag-outline',
      url: '/settings/packages'
    }
  ];

  const inventorySettings: ItemInterface[] = [
    {
      value: t('pages.inventory-movement'),
      icon: 'la:cubes',
      url: '/settings/inventory-movement'
    },
    {
      value: t('pages.products'),
      icon: 'la:cubes',
      url: '/settings/products'
    },
    {
      value: t('pages.damaged'),
      icon: 'la:cubes',
      url: '/settings/damaged'
    }
  ];

  const accountingSettings: ItemInterface[] = [
    {
      value: t('pages.employees'),
      icon: 'la:users',
      url: '/settings/employees'
    },
    {
      icon: 'carbon:receipt',
      color: 'text-gray-500',
      value: t('expenses'),
      url: '/settings/expenses'
    },
    {
      icon: 'solar:dollar-outline',
      color: 'text-gray-500',
      value: t('salaries'),
      url: '/settings/salaries'
    },
    {
      icon: 'solar:dollar-outline',
      color: 'text-gray-500',
      value: t('pages.accounting-restrictions'),
      url: '/settings/accounting-restrictions'
    }
  ];

  const advancedSettings: ItemInterface[] = [
    {
      value: t('pages.roles'),
      icon: 'ri:hospital-line',
      url: '/settings/roles',
      permission: 'roles'
    },
    {
      value: t('pages.2fa'),
      icon: 'oui:app-security',
      url: '/settings/2fa'
    },
    {
      value: t('pages.website-settings'),
      icon: 'gg:website',
      url: '/settings/website'
    },
    {
      value: t('pages.payments-settings'),
      icon: 'formkit:visa',
      url: '/settings/payments'
    },
    {
      value: t('pages.massages-settings'),
      icon: 'material-symbols-light:sms-outline',
      url: '/settings/massages'
    },
    {
      value: t('pages.invoice-design'),
      icon: 'iconamoon:invoice-duotone',
      url: '/settings/invoice'
    }
  ];

  return (
    <Fragment>
      <div className="p-6 space-y-4">
        {!!generalSettings.filter(hasPermissions).length && (
          <>
            <p className="text-sm text-gray-600">{t('general-settings')}</p>

            <div className="grid grid-wrapper gap-4">
              {generalSettings.filter(hasPermissions).map((item) => (
                <MiniDashboardSingleCard
                  key={item.title}
                  item={item}
                />
              ))}
            </div>
          </>
        )}

        {!!inventorySettings.filter(hasPermissions).length && (
          <>
            <p className="text-sm text-gray-600">{t('inventory-settings')}</p>

            <div className="grid grid-wrapper gap-4">
              {inventorySettings.filter(hasPermissions).map((item) => (
                <MiniDashboardSingleCard
                  key={item.title}
                  item={item}
                />
              ))}
            </div>
          </>
        )}

        {!!accountingSettings.filter(hasPermissions).length && (
          <>
            <p className="text-sm text-gray-600">{t('inventory-settings')}</p>

            <div className="grid grid-wrapper gap-4">
              {accountingSettings.filter(hasPermissions).map((item) => (
                <MiniDashboardSingleCard
                  key={item.title}
                  item={item}
                />
              ))}
            </div>
          </>
        )}

        {!!advancedSettings.filter(hasPermissions).length && (
          <>
            <p className="text-sm text-gray-600">{t('advanced-settings')}</p>

            <div className="grid grid-wrapper gap-4">
              {advancedSettings.filter(hasPermissions).map((item) => (
                <MiniDashboardSingleCard
                  key={item.title}
                  item={item}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default Settings;

