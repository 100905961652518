import { RouteObject } from 'react-router-dom';
import {
  BookingDetails,
  Categories,
  Centers,
  CreateBooking,
  CreateCenter,
  CreateOffer,
  DoctorInfo,
  Doctors,
  ErrorPage,
  Expenses,
  Features,
  ForgotPassword,
  Home,
  InventoryMovement,
  Invoices,
  Login,
  Notifications,
  Offers,
  Patients,
  Prices,
  Products,
  Profile,
  Register,
  ResetPassword,
  Roles,
  Salaries,
  Services,
  Settings,
  UpdateCenter,
  UpdateOffer,
  Users
} from 'src/screens';
import { Bookings } from '../screens';
import Packages from 'src/screens/settings/Packages';

export type Route = RouteObject & {
  permission?: string | string[];
};

const globalRoutes: Route[] = [
  {
    path: '*',
    element: <ErrorPage />
  }
];

const authenticationRoutes: Route[] = [
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/account/register',
    element: <Register />
  },
  {
    path: '/account/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/account/forgot-password',
    element: <ForgotPassword />
  },
  ...globalRoutes
];

const routes: Route[] = [
  {
    path: '/',
    element: <Home />,
    permission: 'dashboard'
  },
  {
    path: '/settings/centers',
    element: <Centers />,
    permission: 'centers'
  },

  {
    path: '/settings/centers/create',
    element: <CreateCenter />,
    permission: 'centers'
  },
  {
    path: '/settings/centers/:id',
    element: <UpdateCenter />,
    permission: 'centers'
  },
  {
    path: '/invoices',
    element: <Invoices />,
    permission: 'invoices'
  },
  {
    path: '/bookings',
    element: <Bookings />,
    permission: 'bookings'
  },
  {
    path: '/bookings/add',
    element: <CreateBooking />,
    permission: 'bookings'
  },
  {
    path: '/bookings/:id',
    element: <BookingDetails />,
    permission: 'bookings'
  },
  {
    path: '/offers',
    element: <Offers />,
    permission: 'offers'
  },
  {
    path: '/offers/create',
    element: <CreateOffer />,
    permission: 'offers'
  },
  {
    path: '/offers/:id',
    element: <UpdateOffer />,
    permission: 'offers'
  },

  {
    path: '/settings/users',
    element: <Users />,
    permission: 'users'
  },
  {
    path: '/doctors',
    element: <Doctors />,
    permission: 'doctors'
  },
  {
    path: '/patients',
    element: <Patients />,
    permission: 'patients'
  },
  {
    path: '/doctors/:id',
    element: <DoctorInfo />,
    permission: 'doctors'
  },

  {
    path: '/notifications',
    element: <Notifications />,
    permission: 'notifications'
  },

  {
    path: '/settings',
    element: <Settings />
  },

  {
    path: '/settings/roles',
    element: <Roles />,
    permission: 'roles'
  },

  {
    path: '/account/profile',
    element: <Profile />
  },
  {
    path: '/settings/categories',
    element: <Categories />,
    permission: 'categories'
  },

  {
    path: '/settings/features',
    element: <Features />,
    permission: 'features'
  },
  {
    path: '/settings/services',
    element: <Services />,
    permission: 'services'
  },
  {
    path: '/settings/expenses',
    element: <Expenses />,
    permission: 'expenses'
  },
  {
    path: '/settings/salaries',
    element: <Salaries />,
    permission: 'salaries'
  },
  {
    path: '/settings/prices',
    element: <Prices />,
    permission: 'prices'
  },
  {
    path: '/settings/packages',
    element: <Packages />,
    permission: 'packages'
  },
  {
    path: '/settings/inventory-movement',
    element: <InventoryMovement />,
    permission: 'inventory-movement'
  },
  {
    path: '/settings/products',
    element: <Products />,
    permission: 'products'
  },

  ...globalRoutes
];

const names = [
  'dashboard',
  'centers',
  'center_owners',
  'invoices',
  'offers',
  'reports',
  'messages',
  'notifications',
  'users',
  'admins',
  'categories',
  'roles',
  'sub_categories',
  'services',
  'features',
  'email_templates',
  'email_notifications',
  'regions',
  'cities',
  'districts',
  'settings'
];

export { authenticationRoutes, globalRoutes, names, routes };









