import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Image from 'src/components/shared/Image';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import usePermissions from 'src/hooks/usePermissions';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });
  const { hasAccess } = usePermissions();
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('offers', { params }).then(({ data }) => {
        setPagination(() => data.result?.offers?.pagination || {});
        return data.result?.offers?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.image')}</th>
            <th>{t('form.name')}</th>
            <th>{t('form.center')}</th>
            <th>{t('sales')}</th>
            <th>{t('form.advance-payment')}</th>
            <th>{t('form.price')}</th>
            <th>{t('form.main-price')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <Image
                    width="45"
                    height="45"
                    src={item.image}
                    alt={item.name}
                    className="w-10 h-10 object-cover"
                  />
                </td>

                <td>{item.name || '-'}</td>
                <td>{item.center?.name || '-'}</td>
                <td>{item.sales_count || 0}</td>
                <td>{CurrencyFormatter(item.advance_payment || 0)}</td>
                <td>{CurrencyFormatter(item.price || 0)}</td>
                <td>{CurrencyFormatter(item.main_price || 0)}</td>
                <td>
                  <ChangeStatus
                    pathname={'offers/update_is_active/' + item.id}
                    refetch={refetch}
                    active={!!item.is_active}
                  />
                </td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <Link
                      to={'/offers/' + item.id}
                      className="edit-btn"
                    >
                      <span>{t('edit')}</span>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            <Link
              to="/offers/create"
              className="btn-with-icon !text-gray-600 bg-gray-100"
            >
              <span>{t('add-new')}</span>
            </Link>
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}
