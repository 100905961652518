import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Swal from 'sweetalert2';

export default function Services() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const center = localStorage.getItem('@center');

  useEffect(() => {
    if (!center) {
      Swal.fire({
        icon: 'info',
        title: t('default-center'),
        text: t('center-missed-content'),
        showConfirmButton: false
      }).then(() => {
        navigate('/settings', { replace: true });
      });
      return;
    }
  }, [center]);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['is_active'],
    queryFn: () =>
      axiosInstance
        .get('services', { params: { is_active: 1, center_id: center } })
        .then(({ data }) => {
          return data.result?.services || [];
        })
  });

  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('form.alt-name')}</th>
            <th>{t('form.main-price')}</th>
            <th>{t('form.categories')}</th>
            <th>{t('form.doctors')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>{item.name || '-'}</td>
                <td>{item.alt_name || '-'}</td>
                <td>{CurrencyFormatter(item.price)}</td>
                <td>
                  <div className="flex items-center gap-2">
                    {item.categories?.slice(0, 3)?.map((category: any) => (
                      <p
                        key={category.id}
                        className="btn-with-icon bg-gray-100 !text-gray-600 !py-2 !px-3 !rounded-full"
                      >
                        {category.name}
                      </p>
                    ))}
                    {item.categories?.length > 3 ? (
                      <p className="btn-with-icon bg-gray-100 !text-gray-600 !py-2 !px-3 !rounded-full">
                        +{item.categories?.length - 3}
                      </p>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="flex items-center gap-2">
                    {item.doctors?.slice(0, 3)?.map((doctor: any) => (
                      <p
                        key={doctor.id}
                        className="btn-with-icon bg-gray-100 !text-gray-600 !py-2 !px-3 !rounded-full"
                      >
                        {doctor.name}
                      </p>
                    ))}
                    {item.doctors?.length > 3 ? (
                      <p className="btn-with-icon bg-gray-100 !text-gray-600 !py-2 !px-3 !rounded-full">
                        +{item.doctors?.length - 3}
                      </p>
                    ) : null}
                  </div>
                </td>
                <td>
                  <UpdateForm
                    service={item}
                    refetch={refetch}
                  />
                </td>
              </tr>
            ))}
          </>
        )}
        isEmpty={!data?.length}
        loading={isLoading}
      />
    </div>
  );
}

function UpdateForm({ service, refetch }: { service: any; refetch: () => any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [doctors, setDoctors] = useState<any[]>([]);

  useEffect(() => {
    if (visible) getDoctors();
  }, [visible]);

  function getDoctors() {
    prepareRequest({ url: 'users', params: { is_active: 1, user_type: 'doctor' } }, (data) => {
      if (data) setDoctors(() => data.result?.users || []);
    });
  }

  const {
    formik: { setFieldValue, handleSubmit, values, handleChange }
  } = useForm({
    initialValues: {
      doctor_ids: service?.doctors?.map((doctor: any) => doctor.id),
      service_id: service?.id
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'services/assign_doctors',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          setVisible(false);
          refetch?.();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        type="button"
        className="max-w-fit"
        onClick={() => setVisible(true)}
      >
        <span>{t('update')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${t('update')} ( ${service.name} )`}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group col-span-full">
            <p className="form-label">{t('form.price')}</p>
            <input
              type="number"
              className="form-input form-outline"
              value={values.price}
              name="price"
              onChange={handleChange}
              placeholder="0.00"
              step="any"
            />
            <p className="form-error">{errors.price}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.doctors')}</p>
            <Select
              type="multi"
              value={values.doctor_ids}
              onSelect={(value) => setFieldValue('doctor_ids', value)}
              optionTxt="name"
              optionValue="id"
              options={doctors}
            />
            <p className="form-error">{errors['doctor_ids']}</p>
          </div>
          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}
