import { Icon } from '@iconify/react';
import { ChangeEvent, ReactElement, cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import prepareRequest from 'src/helper/prepareRequest';
import Button from './Button';
import Card from './Card';
import LoadingChip from './LoadingChip';
import Modal from './Modal';
import SearchBox from './SearchBox';

interface IProps {
  RenderItem: ({ item }: { item: any }) => JSX.Element;
  onselect: (response: any) => any;
  pathname: string;
  params: any;
  responseKey: string;
  emptyComponent?: ReactElement;
}

export default function SearchModal({
  RenderItem,
  onselect,
  pathname,
  params,
  responseKey,
  emptyComponent
}: IProps) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any>({});
  const [results, setResults] = useState<any[]>();

  useEffect(() => {
    if (visible) {
      setSearch(undefined);
      setResult({});
      setResults([]);
    }
  }, [visible]);

  function getResults(ev: ChangeEvent<HTMLInputElement>) {
    const value = ev.target.value;
    setSearch(value);
    setIsLoading(true);
    prepareRequest(
      { url: pathname, params: { is_active: 1, page: 1, search_key: value, ...params } },
      (data) => {
        if (data) setResults(() => data.result?.[responseKey]?.data || []);
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <>
      <button
        type="button"
        className="btn-with-icon !py-2 !px-4 !rounded-full !text-gray-600 bg-gray-100"
        onClick={() => setVisible(true)}
      >
        <Icon
          icon="uil:search"
          width="16"
          height="16"
        />
        <span>{t('search')}</span>
      </button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <div className="w-full space-y-4">
          <SearchBox
            className="w-full"
            onChange={getResults}
            defaultValue={search}
          />
          <Card className="!p-0">
            {isLoading && <LoadingChip />}
            <ul className="divide-y divide-gray-200 overflow-y-auto max-h-96">
              {results?.map((response) => (
                <li
                  className="py-4 px-6"
                  key={response.id}
                >
                  <button
                    type="button"
                    onClick={() => setResult(() => (result.id == response.id ? {} : response))}
                    className="flex items-center gap-3 cursor-pointer w-full"
                  >
                    <Icon
                      icon={result.id == response.id ? 'akar-icons:radio-fill' : 'akar-icons:radio'}
                      width="18"
                      height="18"
                      className={
                        'transition shrink-0 ' + result.id == response.id
                          ? 'text-blue-600'
                          : 'text-gray-600'
                      }
                    />
                    <div className="flex-1 text-start">
                      <RenderItem item={response} />
                    </div>
                  </button>
                </li>
              ))}

              {!results?.length ? (
                <li className="py-4 px-6">
                  {emptyComponent ? (
                    cloneElement(emptyComponent)
                  ) : (
                    <p className="text-sm text-center text-gray-500">{t('no-data')}</p>
                  )}
                </li>
              ) : null}
            </ul>
          </Card>
          <Button
            variant="primary"
            type="button"
            disabled={!result.id}
            onClick={() => {
              onselect(result);
              setVisible(false);
            }}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </div>
      </Modal>
    </>
  );
}
