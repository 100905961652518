import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface GlobalInterface {
  response: any;
  centers: any[];
  center: string | null;
}

const initialState: GlobalInterface = {
  response: null,
  centers: [],
  center: sessionStorage.getItem('@center')
};

export const globalSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<any>) => {
      state.response = action.payload;
    },
    setCenters: (state, action: PayloadAction<any>) => {
      state.centers = action.payload;
    },
    setCenter: (state, action: PayloadAction<any>) => {
      state.center = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setResponse, setCenters, setCenter } = globalSlice.actions;

export default globalSlice.reducer;
