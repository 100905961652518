import { serialize } from 'object-to-formdata';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Card from 'src/components/shared/Card';
import Editor from 'src/components/shared/Editor';
import FileUploader from 'src/components/shared/FileUploader';
import Select from 'src/components/shared/Select';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Swal from 'sweetalert2';

const Create: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const [centers, setCenters] = React.useState<any[]>([]);
  const [doctors, setDoctors] = React.useState<any[]>([]);
  const center = localStorage.getItem('@center');
  const navigate = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!center) {
      Swal.fire({
        icon: 'info',
        title: t('default-center'),
        text: t('center-missed-content'),
        showConfirmButton: false
      }).then(() => {
        navigate('/settings', { replace: true });
      });
      return;
    }
  }, [center]);

  React.useEffect(() => {
    if (!rerender) return;
    if (rerender) {
      Promise.all([GetDoctors()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        });

      rerender = false;
    }
  }, []);

  const GetDoctors = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('users', {
        params: { is_active: 1, user_type: 'doctor' }
      });
      setDoctors(data?.result?.users);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="p-6">
      <AddFormBody
        centers={centers}
        doctors={doctors}
      />
    </div>
  );
};

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  info: string | undefined;
  alt_info: string | undefined;
  features: string | undefined;
  alt_features: string | undefined;
  terms: string | undefined;
  alt_terms: string | undefined;
  price: string | undefined;
  main_price: string | undefined;
  advance_payment: string | undefined;
  center_id: string | unknown;
  doctor_ids: string[] | undefined;
  images: File[] | undefined;
}

const AddFormBody = ({ centers, doctors }: { centers: any[]; doctors: any[] }): JSX.Element => {
  const center = localStorage.getItem('@center');
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    info: undefined,
    alt_info: undefined,
    features: undefined,
    alt_features: undefined,
    terms: undefined,
    alt_terms: undefined,
    price: undefined,
    main_price: undefined,
    advance_payment: undefined,
    center_id: center,
    doctor_ids: undefined,
    images: []
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { formik, handleChange } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});

      const fd = serialize(values, {
        booleansAsIntegers: true,
        indices: true
      });
      prepareRequest(
        {
          url: 'offers/add',
          data: fd,
          method: 'post'
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          navigate('/offers', { replace: true });
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <Card>
      <form
        className="space-y-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1  gap-4">
          <div className="space-y-4">
            {/* <div className="form-group">
              <label
                htmlFor="image"
                className="form-label"
              >
                {t('form.image')}
              </label>
              <InputFile
                defaultValue={formik.values.image}
                onValueChange={function (e: any): void {
                  formik.setFieldValue('image', e);
                }}
                accept="image/*"
              />
              {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
            </div> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="form-label">{t('form.name')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.name}
                  onChange={(e) => handleChange('name', e)}
                />
                {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('form.alt-name')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.alt_name}
                  onChange={(e) => handleChange('alt_name', e)}
                />
                {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">{t('form.doctors')}</label>
              <Select
                type={'multi'}
                options={doctors}
                value={formik.values.doctor_ids}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('doctor_ids', value);
                }}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.doctor_ids ? <span className="form-error">{errors?.doctor_ids}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('form.advance-payment')}</label>
              <input
                type="number"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.advance_payment}
                onChange={(e) => handleChange('advance_payment', e)}
              />
              {errors?.advance_payment ? (
                <span className="form-error">{errors?.advance_payment}</span>
              ) : null}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="form-label">{t('form.main-price')}</label>
                <input
                  type="number"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.main_price}
                  onChange={(e) => handleChange('main_price', e)}
                />
                {errors?.main_price ? (
                  <span className="form-error">{errors?.main_price}</span>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('form.price')}</label>
                <input
                  type="number"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.price}
                  onChange={(e) => handleChange('price', e)}
                />
                {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div className="form-group">
              <label className="form-label">{t('form.info')}</label>
              <Editor
                onChange={(value) => formik.setFieldValue('info', value)}
                value={formik.values.info}
              />
              {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('form.alt-info')}</label>
              <Editor
                onChange={(value) => formik.setFieldValue('alt_info', value)}
                value={formik.values.alt_info}
              />
              {errors?.alt_info ? <span className="form-error">{errors?.alt_info}</span> : null}
            </div>
           
            <div className="form-group">
              <p className="form-label">{t('form.images')}</p>
              <FileUploader
                name="images"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('images', event.target.files);
                }}
                accept="image/*"
                multiple
              />
              <p className="form-error">{errors?.images}</p>
            </div>
          </div>
        </div>

        <div className="inline-flex gap-3 flex-wrap">
          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('submit')}</span>
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default Create;
