import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Button from '../Button';
import Modal from '../Modal';
import Select from '../Select';
import { roles } from './AddForm';

export default function UpdateForm({ refetch, item }: { refetch: any; item: any }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({
    initialValues: item,
    submitHandler: (values, helper) => {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'roles/update',
          method: 'post',
          data: {
            ...values
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          helper.resetForm();
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  const isSelected = useCallback(
    (key: string, access: string) => {
      return values.permissions.some((e: string) => e === `${access} ${key}`);
    },
    [values.permissions]
  );

  const onAccessChange = useCallback(
    (checked: boolean, key: string, access: string) => {
      let result;
      const permissions = values.permissions;
      const filterPermissions = permissions.filter(
        (permission: string) => permission !== `${access} ${key}`
      );

      if (access !== 'show') {
        const existed = isSelected(key, 'show');

        result = !checked
          ? filterPermissions
          : new Array().concat(
              ...[
                ...permissions,
                existed ? `${access} ${key}` : [`show ${key}`, `${access} ${key}`]
              ]
            );
      } else {
        result = !checked ? filterPermissions : [...permissions, `${access} ${key}`];
      }

      setFieldValue('permissions', result);
    },
    [values.permissions]
  );

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="!max-w-fit"
      >
        <span>{t('edit')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-md"
        title={t('edit') + '( ' + item.name + ' )'}
      >
        <form
          className="space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="form-group">
              <p className="form-label">{t('form.name')}</p>
              <input
                type="text"
                className="form-input form-outline"
                value={values.name}
                name="name"
                onChange={handleChange}
                placeholder="..."
              />
              <p className="form-error">{errors.name}</p>
            </div>
            <div className="form-group">
              <p className="form-label">{t('form.alt-name')}</p>
              <input
                type="text"
                className="form-input form-outline"
                value={values.alt_name}
                name="alt_name"
                onChange={handleChange}
                placeholder="..."
              />
              <p className="form-error">{errors.alt_name}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.permissions')}</label>
            <Select
              type={'multi'}
              options={roles.map((role) => ({
                ...role,
                name: t('pages.' + role.name)
              }))}
              value={values.permissions}
              onSelect={function (value: any): any {
                return setFieldValue('permissions', value);
              }}
              optionTxt={'name'}
              optionValue={'key'}
            />

            {errors?.permissions ? <span className="form-error">{errors?.permissions}</span> : null}
          </div>
          {/* <div>
            <Table
              RenderHead={() => (
                <tr>
                  <th></th>
                  <th>{t('view')}</th>
                  <th>{t('create')}</th>
                  <th>{t('edit')}</th>
                  <th>{t('update')}</th>
                  <th>{t('delete')}</th>
                </tr>
              )}
              RenderBody={() => (
                <>
                  {roles.map((role, index) => (
                    <tr key={index}>
                      <td>{t('pages.' + role.name)}</td>
                      <td>
                        {role.access.includes('show') && (
                          <Switcher
                            checked={isSelected(role.key, 'show')}
                            onChange={(v) => onAccessChange(v, role.key, 'show')}
                          />
                        )}
                      </td>
                      <td>
                        {role.access.includes('create') && (
                          <Switcher
                            checked={isSelected(role.key, 'create')}
                            onChange={(v) => onAccessChange(v, role.key, 'create')}
                          />
                        )}
                      </td>
                      <td>
                        {role.access.includes('edit') && (
                          <Switcher
                            checked={isSelected(role.key, 'edit')}
                            onChange={(v) => onAccessChange(v, role.key, 'edit')}
                          />
                        )}
                      </td>
                      <td>
                        {role.access.includes('update') && (
                          <Switcher
                            checked={isSelected(role.key, 'update')}
                            onChange={(v) => onAccessChange(v, role.key, 'update')}
                          />
                        )}
                      </td>
                      <td>
                        {role.access.includes('delete') && (
                          <Switcher
                            checked={isSelected(role.key, 'delete')}
                            onChange={(v) => onAccessChange(v, role.key, 'delete')}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            />
          </div> */}
          <Button
            variant="primary"
            className="!max-w-fit"
            type="submit"
            disabled={disabled}
          >
            <span>{t('submit')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}
