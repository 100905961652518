import { AllHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/shared/Button';
import Card from 'src/components/shared/Card';
import DisplayUserCard from 'src/components/shared/DisplayUserCard';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import SearchModal from 'src/components/shared/SearchModal';
import Select from 'src/components/shared/Select';
import AddForm from 'src/components/shared/users/AddForm';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import usePermissions from 'src/hooks/usePermissions';

export default function Create() {
  const { t } = useTranslation();
  const [services, setServices] = useState<any[]>([]);
  const [bookingTimes, setBookingTimes] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const center = localStorage.getItem('@center') || '';

  const [errors, setErrors] = useState<any>({});

  const { hasAccess } = usePermissions();

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({
    initialValues: {
      payment_option: [],
      center_id: center,
      center_ids: center ? [center] : []
    },
    submitHandler: (values: any, helper: any) => {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'bookings/new',
          method: 'post',
          data: {
            ...values,
            mobile: parseFloat(values.mobile?.replace(/\s/gi, ''))
            // center_ids: values.center_id ? [values.center_id] : []
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          helper.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  function getServices() {
    prepareRequest(
      {
        url: 'services',
        params: {
          is_active: 1,
          category_id: values.category_ids,
          doctor_id: values.doctor_id,
          center_id: values.center_id
        }
      },
      (data) => {
        if (data) setServices(() => data.result?.services || []);
      }
    );
  }

  function getBookingTimes() {
    prepareRequest(
      {
        url: 'booking_times',
        params: { doctor_id: values.doctor_id, center_id: center }
      },
      (data) => {
        if (data) setBookingTimes(() => data.result || []);
      }
    );
  }

  useMemo(() => {
    if (values.center_id && values.doctor_id) {
      getServices();
      getBookingTimes();
    }
  }, [values.doctor_id, values.center_id, center]);

  const servicesPrice = useMemo(() => {
    let total = 0;
    const selectedServices = services.filter(
      (service: any) => values.service_ids?.includes(service.id)
    );

    selectedServices.forEach((service: any) => {
      total += service.price;
    });

    return parseFloat(total.toFixed(2));
  }, [values.service_ids]);

  const total = useMemo(() => {
    return parseFloat(
      (
        servicesPrice +
          (values.doctor?.advance_payment_value
            ? values.doctor?.advance_payment_value
            : values.doctor?.price) || 0
      ).toFixed(2)
    );
  }, [servicesPrice, values.doctor]);

  return (
    <div className="p-6">
      <form
        className="space-y-6"
        onSubmit={handleSubmit}
      >
        <Card>
          <ul className="divide-y divide-gray-200">
            <ItemList className="py-4">
              <div className="form-group">
                <div className="flex justify-between gap-3">
                  <p className="form-label">{t('form.patient')}</p>
                  <SearchModal
                    RenderItem={({ item }) => (
                      <>
                        <p className="text-sm">{item.name}</p>
                        <p className="text-sm text-gray-500">{item.mobile}</p>
                      </>
                    )}
                    pathname="users"
                    params={{ user_type: 'patient' }}
                    responseKey="users"
                    onselect={(response) => {
                      setFieldValue('patient', response);
                      setFieldValue('patient_id', response.id);
                    }}
                    emptyComponent={
                      <div className="space-y-2">
                        <p className="text-sm text-center text-gray-600">{t('no-data')}</p>
                        {hasAccess('patients') && <AddForm user_type="patient" />}
                      </div>
                    }
                  />
                </div>
                {values.patient ? (
                  <DisplayUserCard
                    user={values.patient}
                    onDelete={() => {
                      setFieldValue('patient', undefined);
                      setFieldValue('patient_id', undefined);
                    }}
                  />
                ) : null}
                <p className="form-error">{errors.patient_id}</p>
              </div>
            </ItemList>
            <ItemList className="py-4">
              <div className="form-group">
                <p className="form-label">{t('form.categories')}</p>
                <DisplayBookingDoctorCategories
                  onChange={handleChange}
                  values={values.category_ids}
                  center={center}
                />
              </div>
            </ItemList>
            {/* {!!values?.category_ids?.length ? (
              <ItemList className="py-4">
                <div className="form-group">
                  <p className="form-label">{t('form.doctor')}</p>
                  <DisplayBookingDoctors
                    onChange={handleChange}
                    categories={values.category_ids}
                    doctor={values.doctor_id}
                    center={center}
                  />
                  <p className="form-error">{errors.doctor_id}</p>
                </div>
              </ItemList>
            ) : null} */}

            <ItemList className="py-4">
              <div className="form-group">
                <p className="form-label">{t('form.doctor')}</p>
                <DisplayBookingDoctors
                  onChange={handleChange}
                  categories={values.category_ids}
                  doctor={values.doctor_id}
                  center={center}
                />
                <p className="form-error">{errors.doctor_id}</p>
              </div>
            </ItemList>

            {!!(values.doctor_id && values.center_id) ? (
              <>
                <ItemList className="py-4">
                  <div className="form-group">
                    <p className="form-label">{t('form.booking-time')}</p>
                    <select
                      className="form-select form-outline"
                      value={values.booking_time_parent}
                      name="booking_time_parent"
                      onChange={handleChange}
                      defaultValue=""
                    >
                      <option value="">-- {t('select')} --</option>
                      {bookingTimes.map((label) => (
                        <option
                          value={JSON.stringify(label)}
                          key={label.day}
                        >
                          {label.day}
                        </option>
                      ))}
                    </select>
                    {values.booking_time_parent ? (
                      <select
                        className="form-select form-outline"
                        value={values.booking_time}
                        name="booking_time"
                        onChange={handleChange}
                        defaultValue=""
                      >
                        <option value="">-- {t('select')} --</option>
                        {JSON.parse(values.booking_time_parent)?.times?.map((time: any) => (
                          <option
                            value={time.value}
                            key={time.label}
                            disabled={!!time.is_booked}
                          >
                            {time.label}
                          </option>
                        ))}
                      </select>
                    ) : null}
                    <p className="form-error">{errors.booking_time}</p>
                  </div>
                </ItemList>
                <ItemList className="py-4">
                  <div className="form-group">
                    <p className="form-label">{t('form.services')}</p>
                    <Select
                      options={services}
                      value={values.service_ids}
                      onSelect={function (value) {
                        setFieldValue('service_ids', value);
                      }}
                      optionTxt={'name'}
                      optionValue={'id'}
                      type="multi"
                    />
                    <p className="form-error">{errors.service_ids}</p>
                  </div>
                </ItemList>
              </>
            ) : null}
          </ul>
        </Card>

        <Card className="space-y-4">
          <ul className="divide-y divide-gray-200">
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('doctor-price')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(values.doctor?.price || 0)}
                </p>
              </div>
            </ItemList> */}
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('form.advance-payment')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(values.doctor?.advance_payment_value || 0)}
                </p>
              </div>
            </ItemList> */}
            <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('services-price')}</p>
                <p className="text-sm font-semibold">{CurrencyFormatter(servicesPrice || 0)}</p>
              </div>
            </ItemList>
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('total')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(
                     || 0
                  )}
                </p>
              </div>
            </ItemList> */}
          </ul>
        </Card>

        <Card className="space-y-4">
          <ul className="divide-y divide-gray-200">
            <ItemList className="py-3">
              <PayItem
                title="cash"
                subtitle="pay-cash"
                price={total}
                value="cash"
                id="cash"
                onChange={handleChange}
              />
            </ItemList>
            <ItemList className="py-3">
              <PayItem
                title="credit"
                subtitle="pay-credit"
                price={total}
                value="visa"
                id="visa"
                onChange={handleChange}
              />
            </ItemList>
          </ul>
        </Card>

        <p className="form-error">{errors.payment_option}</p>

        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('submit')}</span>
        </Button>
      </form>
    </div>
  );
}

function PayItem({
  title,
  subtitle,
  price,
  ...rest
}: AllHTMLAttributes<HTMLInputElement> & {
  title: string;
  subtitle: string;
  price: number;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-4">
      <input
        type="checkbox"
        name="payment_option"
        className="form-checkbox w-4 h-4 shrink-0"
        {...rest}
      />
      <label
        htmlFor={rest.id}
        className="flex-1 block cursor-pointer"
      >
        <p className="text-sm font-semibold">{t(title)}</p>
        <p className="text-sm text-gray-500">{t(subtitle)}</p>
      </label>
      {/* <p className="text-sm font-semibold">{CurrencyFormatter(price)}</p> */}
    </div>
  );
}

function DisplayBookingDoctors({
  onChange,
  categories,
  doctor,
  center
}: {
  onChange: any;
  categories: any[];
  doctor: any;
  center: string;
}) {
  const [doctors, setDoctors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    getDoctors();
  }, [categories]);

  function getDoctors() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'users',
        params: { is_active: 1, center_id: center, category_ids: categories, user_type: 'doctor' }
      },
      (data) => {
        if (data) setDoctors(() => data.result?.users || []);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading)
    return (
      <div className="grid grid-wrapper gap-2">
        {Array.from({ length: 4 }).map((_e, index) => (
          <div
            key={index}
            className="w-full p-4 rounded bg-gray-200 animate-pulse"
          ></div>
        ))}
      </div>
    );

  return (
    <ul className="flex items-center flex-wrap gap-2">
      {doctors.map((item: any) => (
        <li key={item.id}>
          <input
            type="radio"
            name="doctor_id"
            id={item.id}
            value={item.id}
            hidden
            onChange={onChange}
            className="peer"
            checked={doctor == item.id}
          />
          <label
            htmlFor={item.id}
            className="text-sm transition selection:w-full block cursor-pointer rounded bg-white border border-gray-200 text-gray-600 peer-checked:border-primary"
          >
            <div className="flex items-center gap-4 p-3">
              <Image
                src={item.photo}
                className="shrink-0 w-10 h-10 rounded-full object-cover"
                width="40"
                height="40"
              />
              <div className="flex-1 space-y-">
                <p>{item.name || '-'} </p>
                <p>{item.qualification || '-'} </p>
              </div>
            </div>
          </label>
        </li>
      ))}
      {!doctors.length && (
        <li>
          <p className="text-sm text-gray-600">{t('no-data')}</p>
        </li>
      )}
    </ul>
  );
}

function DisplayBookingDoctorCategories({
  onChange,
  values,
  center
}: {
  onChange: any;
  values: any[];
  center: string;
}) {
  const [categories, setCategories] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    setIsLoading(true);
    prepareRequest({ url: 'categories', params: { is_active: 1, center_id: center } }, (data) => {
      if (data) setCategories(() => data.result?.categories || []);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading)
    return (
      <div className="grid grid-wrapper gap-2">
        {Array.from({ length: 4 }).map((_e, index) => (
          <div
            key={index}
            className="w-full p-4 rounded bg-gray-200 animate-pulse"
          ></div>
        ))}
      </div>
    );

  return (
    <ul className="flex items-center flex-wrap gap-2">
      {categories.map((category: any) => (
        <li key={category.id}>
          <input
            type="checkbox"
            name="category_ids"
            id={category.id}
            value={category.id}
            hidden
            onChange={onChange}
            className="peer"
            checked={values?.some((c) => c == category.id)}
          />
          <label
            htmlFor={category.id}
            className="text-sm transition selection:w-full block cursor-pointer py-2 px-4 rounded bg-gray-200 text-gray-600 peer-checked:bg-primary peer-checked:text-white"
          >
            {category.name} - {category.alt_name}
          </label>
        </li>
      ))}
      {!categories.length && (
        <li>
          <p className="text-sm text-gray-600">{t('no-data')}</p>
        </li>
      )}
    </ul>
  );
}

