import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Button from '../Button';
import Mobile from '../Mobile';
import Modal from '../Modal';
import Password from '../Password';
import Select from '../Select';

export default function UpdateForm({ item, refetch }: { item: any; refetch?: any }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any[]>([]);
  const [centers, setCenters] = useState<any[]>([]);
  const [relatives, setRelatives] = useState<any[]>([]);

  useEffect(() => {
    setErrors({});
    if (!visible) return;
    getCenters();
    getRelatives();
    if (item.user_type === 'center_user') getRoles();
  }, [visible]);

  function getCenters() {
    prepareRequest(
      {
        url: 'centers',
        params: { is_active: 1 }
      },
      (data) => {
        if (data) setCenters(() => data.result?.centers || []);
      }
    );
  }

  function getRoles() {
    prepareRequest(
      {
        url: 'roles',
        params: { is_active: 1 }
      },
      (data) => {
        if (data) setRoles(() => data.result?.roles || []);
      }
    );
  }
  function getRelatives() {
    prepareRequest(
      {
        url: 'relative_types',
        params: { is_active: 1 }
      },
      (data) => {
        if (data) setRelatives(() => data.result?.relative_types || []);
      }
    );
  }

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({
    initialValues: {
      ...item,
      relative_type_id: item.relative_type?.id
    },
    submitHandler: (values, helper) => {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'users/update',
          method: 'post',
          data: {
            ...values,
            mobile: values.mobile?.replace(/\s/gi, '')
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          helper.resetForm();
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="!max-w-fit"
      >
        <span>{t('edit')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-sm"
        title={t('edit') + '( ' + item.name + ' )'}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              value={values.name}
              name="name"
              onChange={handleChange}
              placeholder="..."
            />
            <p className="form-error">{errors.name}</p>
          </div>
          {item.user_type === 'doctor' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group col-span-full">
                <p className="form-label">{t('form.price')}</p>
                <input
                  type="number"
                  className="form-input form-outline"
                  value={values.price}
                  name="price"
                  onChange={handleChange}
                  placeholder="0.00"
                  step="any"
                />
                <p className="form-error">{errors.price}</p>
              </div>
              <div className="form-group">
                <p className="form-label">{t('form.advance-payment')}</p>
                <input
                  type="number"
                  className="form-input form-outline"
                  value={values.advance_payment_value}
                  name="advance_payment_value"
                  onChange={handleChange}
                  placeholder="0.00"
                  step="any"
                />
                <p className="form-error">{errors.advance_payment_value}</p>
              </div>
              <div className="form-group">
                <p className="form-label">
                  {t('form.advance-payment')} {t('percentage')}
                </p>
                <input
                  type="number"
                  className="form-input form-outline"
                  value={values.advance_payment_percentage}
                  name="advance_payment_percentage"
                  onChange={handleChange}
                  placeholder="00.0"
                  step="any"
                />
                <p className="form-error">{errors.advance_payment_percentage}</p>
              </div>
              <div className="form-group">
                <p className="form-label">{t('form.duration')}</p>
                <input
                  type="number"
                  className="form-input form-outline"
                  value={values.duration}
                  name="duration"
                  onChange={handleChange}
                  placeholder="00"
                  step="01"
                />
                <p className="form-error">{errors.duration}</p>
              </div>
              <div className="form-group">
                <p className="form-label">{t('form.break-time')}</p>
                <input
                  type="number"
                  className="form-input form-outline"
                  value={values.breaktime}
                  name="breaktime"
                  onChange={handleChange}
                  placeholder="00"
                  step="01"
                />
                <p className="form-error">{errors.breaktime}</p>
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <p className="form-label">{t('form.email')}</p>
            <input
              type="email"
              className="form-input form-outline"
              value={values.email}
              name="email"
              onChange={handleChange}
              placeholder="example@example.com"
            />
            <p className="form-error">{errors.email}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.mobile')}</p>
            <Mobile
              className="form-input form-outline"
              value={values.mobile}
              name="mobile"
              onChange={handleChange}
            />
            <p className="form-error">{errors.mobile}</p>
          </div>
          {values.user_type === 'patient' ? (
            <div className="form-group">
              <p className="form-label">{t('form.relative')}</p>
              <select
                className="form-select form-outline"
                value={values.relative_type_id}
                name="relative_type_id"
                onChange={handleChange}
                defaultValue=""
              >
                <option value="">-- {t('select')} --</option>
                {relatives.map((relative) => (
                  <option
                    value={relative.id}
                    key={relative.id}
                  >
                    {relative.name} - {relative.alt_name}
                  </option>
                ))}
              </select>
              <p className="form-error">{errors.relative_type_id}</p>
            </div>
          ) : null}
          {!['patient'].includes(item.user_type) ? (
            <>
              <div className="form-group">
                <p className="form-label">{t('form.password')}</p>
                <Password
                  className="form-input form-outline"
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                />
                <p className="form-error">{errors.password}</p>
              </div>
              <div className="form-group">
                <p className="form-label">{t('form.centers')}</p>
                <Select
                  type="multi"
                  options={centers}
                  optionTxt="name"
                  optionValue="id"
                  value={values.center_ids}
                  onSelect={(value) => setFieldValue('center_ids', value)}
                />
                <p className="form-error">{errors.center_ids}</p>
              </div>
              {item.user_type !== 'doctor' ? (
                <div className="form-group">
                  <p className="form-label">{t('form.role')}</p>
                  <select
                    className="form-select form-outline"
                    value={values.role_id}
                    name="role_id"
                    onChange={handleChange}
                    defaultValue=""
                  >
                    <option value="">-- {t('select')} --</option>
                    {roles.map((role) => (
                      <option
                        value={role.id}
                        key={role.id}
                      >
                        {role.name}
                      </option>
                    ))}
                  </select>
                  <p className="form-error">{errors.role_id}</p>
                </div>
              ) : null}
            </>
          ) : (
            <></>
          )}

          <div className="form-group">
            <p className="form-label">{t('form.gender')}</p>
            <div className="flex flex-wrap gap-4">
              <div className="flex gap-4 items-center flex-1 shrink-0">
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={values.gender === 'male'}
                  onChange={handleChange}
                  className="from-radio shrink-0"
                />
                <label
                  htmlFor="male"
                  className="cursor-pointer form-label"
                >
                  {t('male')}
                </label>
              </div>
              <div className="flex gap-4 items-center flex-1 shrink-0">
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  checked={values.gender === 'female'}
                  onChange={handleChange}
                  className="from-radio shrink-0"
                />
                <label
                  htmlFor="female"
                  className="cursor-pointer form-label"
                >
                  {t('female')}
                </label>
              </div>
            </div>
            <p className="form-error">{errors.password}</p>
          </div>

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            {t('save-changes')}
          </Button>
        </form>
      </Modal>
    </>
  );
}
