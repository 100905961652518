import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Swal from 'sweetalert2';
import Button from '../Button';
import Switcher from '../Switcher';
import Table from '../tables/Table';

export default function DisplayDoctorServices({
  services,
  refetch,
  assigned_services
}: {
  assigned_services: any[];
  services: any[];
  refetch: () => any;
}) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const [errors, setErrors] = useState<any>({});
  const { t } = useTranslation();

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue, handleBlur }
  } = useForm({
    initialValues: { services: assigned_services, doctor_id: id },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'users/assign_services',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch();
        }
      ).finally(() => setDisabled(false));
    }
  });

  const isSelected = useCallback(
    (id: string) => {
      return values.services?.some((e: any) => e.id == id);
    },
    [values.services]
  );
  const index = useCallback(
    (id: string) => {
      return values.services?.findIndex((e: any) => e.id == id);
    },
    [values.services]
  );

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <Table
        RenderHead={() => (
          <tr>
            <th></th>
            <th>{t('form.name')}</th>
            <th>{t('form.main-price')}</th>
            <th>{t('form.price')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {values.services.map((item: any) => (
              <tr>
                <td>
                  <Switcher
                    checked={isSelected(item.id)}
                    onChange={(checked) => {
                      if (!checked) {
                        Swal.fire({
                          icon: 'info',
                          title: t('are-you-sure'),
                          text: t('do-process'),
                          showCancelButton: true,
                          confirmButtonText: t('yes'),
                          cancelButtonText: t('cancel')
                        }).then(({ isConfirmed }) => {
                          if (isConfirmed) {
                            setFieldValue(
                              'services',
                              values.services?.filter((e: any) => e.id !== item.id)
                            );
                          }
                        });
                      } else {
                        setFieldValue(
                          'services',

                          [...values.services, item]
                        );
                      }
                    }}
                  />
                </td>
                <td>{item.name}</td>
                <td>{CurrencyFormatter(item.price)}</td>
                <td>
                  <input
                    type="number"
                    name={`services.${index(item.id)}.price`}
                    defaultValue={values.services?.[index(item.id)]?.price}
                    onBlur={(e) =>
                      setFieldValue(`services.${index(item.id)}.price`, e.target.value)
                    }
                    className="form-input text-center"
                    disabled={!isSelected(item.id)}
                  />
                  <p className="form-error">{errors[`services.${index(item.id)}.price`]}</p>
                  <p className="form-error">{errors[`services.${index(item.id)}.id`]}</p>
                </td>
              </tr>
            ))}
          </>
        )}
        isEmpty={!values.services?.length}
      />
      <Button
        variant="primary"
        disabled={disabled}
        type="submit"
      >
        <span>{t('save-changes')}</span>
      </Button>
    </form>
  );
}
