import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import { setTitle } from 'src/reducers/root';

export default function id() {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<any>({});
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getBooking();
  }, []);

  async function getBooking() {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get('bookings/' + id);
      let booking = data.result.booking || {};

      setResponse(() => booking);
      dispatch(setTitle(`#${booking.id} - ${booking.offer?.name || booking.doctor?.name}`));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  function completeBooking() {
    setDisabled(true);
    prepareRequest({ url: 'bookings/complete/' + id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      getBooking();
    }).finally(() => setDisabled(false));
  }

  function markBookingAsPaid() {
    setDisabled(true);
    prepareRequest({ url: 'bookings/mark_paid/' + id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      getBooking();
    }).finally(() => setDisabled(false));
  }

  function cancelBooking() {
    setDisabled(true);
    prepareRequest({ url: 'bookings/cancel/' + id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      getBooking();
    }).finally(() => setDisabled(false));
  }

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="flex w-full p-6 gap-4 flex-col lg:flex-row">
      <div className="space-y-4 w-full lg:w-4/6">
        <Card className="divide-y divide-gray-200 space-y-4">
          <div className="pb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">{t('form.center')}</p>
              <div className="space-y-1">
                <p>{response.center?.name}</p>
                {response.center?.address ? (
                  <p className="text-sm text-gray-600">{response.center?.address}</p>
                ) : null}
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">{t('form.booking-time')}</p>
              <div className="space-y-1">
                {/* <SharedTime date={response.booking_time}  /> */}
                {response.booking_time}
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">{t('form.patient')}</p>
              <div className="space-y-1">
                <p>{response.patient?.name}</p>
                {response.patient?.mobile ? (
                  <p className="text-sm text-gray-600">{response.patient?.mobile}</p>
                ) : null}
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">{t('form.doctor')}</p>
              <div className="space-y-1">
                <p>{response.doctor?.name}</p>
                {response.doctor?.mobile ? (
                  <p className="text-sm text-gray-600">{response.doctor?.mobile}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="space-y-2 pt-4">
            <p className="text-sm text-gray-500 font-medium">{t('form.services')}</p>
            <ul className="divide-y divide-gray-200">
              {response.services?.map((service: any) => (
                <ItemList
                  className="py-3"
                  key={service.key}
                >
                  <div className="flex justify-between gap-4">
                    <p className="text-sm">{service.name}</p>
                    <p className="text-sm text-teal-500 font-medium">
                      {CurrencyFormatter(service.price)}
                    </p>
                  </div>
                </ItemList>
              ))}
              {!response.services?.length && (
                <li className="py-3 first:pt-0 last:pb-0">
                  <p className="text-sm text-gray-500 py-2 px-3 rounded bg-gray-100 text-center">
                    {t('no-data')}
                  </p>
                </li>
              )}
            </ul>
          </div>
          <div className="space-y-2 pt-4">
            <p className="text-sm text-gray-500 font-medium">{t('form.offer')}</p>
            <ul>
              {response.offer ? (
                <ItemList className="py-3">
                  <div className="flex items-center gap-4">
                    <Image
                      alt={response.offer?.name}
                      src={response.offer?.image}
                      className="w-12 h-12 object-contain shrink-0"
                    />
                    <div className="flex-1">
                      <p className="text-sm font-semibold">
                        {CurrencyFormatter(response.offer?.price || 0)}
                      </p>
                      <p className="text-sm text-gray-500 font-medium">{response.offer?.name}</p>
                    </div>
                  </div>
                </ItemList>
              ) : (
                <li className="py-3 first:pt-0 last:pb-0">
                  <p className="text-sm text-gray-500 py-2 px-3 rounded bg-gray-100 text-center">
                    {t('no-data')}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </Card>
      </div>
      <div className="space-y-4 w-full lg:w-2/6">
        <Card>
          <ul className="divide-y divide-gray-200">
            <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('status')}</p>
                <p className="text-sm font-semibold">{t('statuses.' + response.status)}</p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('payment-status')}</p>
                <p className="text-sm font-semibold">
                  {t(+response.is_paid ? 'statuses.paid' : 'statuses.not-paid')}
                </p>
              </div>
            </ItemList>
          </ul>
        </Card>
        <Card>
          <ul className="divide-y divide-gray-200">
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('doctor-price')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(response.doctor?.price || 0)}
                </p>
              </div>
            </ItemList> */}
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('form.advance-payment')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(response.advance_payment || 0)}
                </p>
              </div>
            </ItemList> */}
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('services-price')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(response.services_price || 0)}
                </p>
              </div>
            </ItemList> */}
            {/* <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('cash-amount')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(response.cash_amount || 0)}
                </p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('card-amount')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(response.card_amount || 0)}
                </p>
              </div>
            </ItemList> */}
            <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('subtotal')}</p>
                <p className="text-sm font-semibold">
                  {CurrencyFormatter(response.sub_total || 0)}
                </p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex justify-between gap-3">
                <p className="text-sm text-gray-500 font-medium">{t('total')}</p>
                <p className="text-sm font-semibold">{CurrencyFormatter(response.total || 0)}</p>
              </div>
            </ItemList>
          </ul>
        </Card>
        <div className="space-y-2">
          {!['cancelled', 'completed'].includes(response.status) ? (
            <ButtonWithCallback
              callback={completeBooking}
              className="btn-with-icon bg-teal-600 !text-sm w-full"
              options={{
                title: t('are-you-sure'),
                text: t('do-process'),
                showCancelButton: true,
                confirmButtonText: t('yes'),
                cancelButtonText: t('cancel')
              }}
              disabled={disabled}
            >
              <span>{t('complete-booking')}</span>
            </ButtonWithCallback>
          ) : null}
          {!response.is_paid && !['cancelled'].includes(response.status) ? (
            <ButtonWithCallback
              callback={markBookingAsPaid}
              className="btn-with-icon bg-blue-600 !text-sm w-full"
              options={{
                title: t('are-you-sure'),
                text: t('do-process'),
                showCancelButton: true,
                confirmButtonText: t('yes'),
                cancelButtonText: t('cancel')
              }}
              disabled={disabled}
            >
              <span>{t('mark-booking-as-paid')}</span>
            </ButtonWithCallback>
          ) : null}
          {response.status !== 'cancelled' ? (
            <ButtonWithCallback
              callback={cancelBooking}
              className="btn-with-icon bg-red-600 !text-sm w-full"
              options={{
                title: t('are-you-sure'),
                text: t('do-process'),
                showCancelButton: true,
                confirmButtonText: t('yes'),
                cancelButtonText: t('cancel')
              }}
              disabled={disabled}
            >
              <span>{t('cancel-booking')}</span>
            </ButtonWithCallback>
          ) : null}
        </div>
      </div>
    </div>
  );
}
