import { ButtonHTMLAttributes, useState } from 'react';
import prepareRequest from 'src/helper/prepareRequest';

interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  pathname: string;
  callback?: (result: any) => void;
  filename: string;
}

export default function ExportAttachment({ pathname, callback, filename, ...rest }: IProps) {
  const [disabled, setDisabled] = useState<boolean>(false);

  function exportAttachment() {
    setDisabled(true);
    prepareRequest(
      {
        url: pathname,
        method: 'get',
        responseType: 'blob'
      },
      (data) => {
        const link = document.createElement('a') as HTMLAnchorElement;
        const href = URL.createObjectURL(data);
        link.href = href;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(href);
        callback?.({
          data,
          href,
          filename
        });
        link.remove();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <button
      onClick={exportAttachment}
      disabled={disabled}
      {...rest}
    ></button>
  );
}
