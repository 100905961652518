import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Switcher from 'src/components/shared/Switcher';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function AssignCenterCategories() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    getCenter();
  }, [id]);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['is_active'],
    queryFn: () =>
      axiosInstance.get('categories', { params: { is_active: 1 } }).then(({ data }) => {
        return data.result?.categories || [];
      })
  });

  const {
    formik: { setFieldValue, handleSubmit, values }
  } = useForm({
    initialValues: { category_ids: [], center_id: id },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'centers/assign_categories',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  function getCenter() {
    prepareRequest(
      {
        url: 'centers/' + id
      },
      (data) => {
        setFieldValue(
          'category_ids',
          data?.result?.center?.categories?.map((category: any) => category.id)
        );
      }
    );
  }

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('actions')}</th>
            <th>{t('form.name')}</th>
            <th>{t('form.alt-name')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <Switcher
                    checked={values.category_ids.includes(item.id)}
                    onChange={(checked) =>
                      setFieldValue(
                        'category_ids',
                        checked
                          ? [...values.category_ids, item.id]
                          : values.category_ids.filter((id: any) => id !== item.id)
                      )
                    }
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.alt_name}</td>
              </tr>
            ))}
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
      />
      <Button
        variant="primary"
        type="submit"
        disabled={disabled}
      >
        <span>{t('save-changes')}</span>
      </Button>
    </form>
  );
}
