import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setTitle } from 'src/reducers/root';
import { RootState } from 'src/store';

const pages: Record<string, string> = {
  '/bookings/add': 'new-booking',
  '/': 'dashboard',
  '/bookings': 'bookings',
  '/doctors': 'doctors',
  '/patients': 'patients',
  '/invoices': 'invoices',
  '/offers': 'offers',
  '/settings': 'settings',
  '/settings/categories': 'categories',
  '/settings/services': 'services',
  '/settings/centers': 'centers',
  '/settings/centers/add': 'new-center',
  '/settings/users': 'users',
  '/settings/roles': 'roles'
};

export default function CurrentPageTitle() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { title } = useSelector((state: RootState) => state.root);
  const dispatch = useDispatch();

  useEffect(() => {
    let str;

    str = pages[pathname] ? t(`pages.${pages[pathname]}`) : title;

    dispatch(setTitle(str));
  }, [pathname, title]);

  if (!title) return <></>;

  return <p className="font-semibold text-gray-600 truncate">{title}</p>;
}
