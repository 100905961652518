import React, { FC, HtmlHTMLAttributes, InputHTMLAttributes, useMemo } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/Table';
import SharedTime from 'src/components/shared/SharedTime';
import { Link } from 'react-router-dom';
import Select from 'src/components/shared/Select';
import { ConfirmAlert } from 'src/hooks/alerts';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const Coupons: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [subscribeVisible, setSubscribeVisible] = React.useState<boolean>(false);
  const [subscribeItem, setSubscribeItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [centers, setCenters] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const { center } = useSelector((state: RootState) => state.globalResponse);
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems(), GetCenters()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetCenters = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('centers', { params: { is_active: 1 } });
      setCenters(data?.result?.centers);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'search_key',
        'discount_type'
      ]);

      const { data } = await axiosInstance.get('coupons', {
        params: { is_active: is_active, ...paginate, center_id: center }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.coupons;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('coupons/update_is_active/' + id);
      await GetItems();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('coupons/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const CopyCode = React.useCallback((str: string) => {
    window.navigator.clipboard.writeText(str).then(() => alert('Item copied successfully ' + str));
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr className=" bg-gray-100 border-b border-b-gray-200 ">
              <th className=" font-medium p-4 text-slate-600 text-start">Name</th>
              <th className=" font-medium p-4  text-slate-600 text-start">Code</th>
              <th className=" font-medium p-4 text-slate-600 text-start">
                <select
                  name="status-filter"
                  id="status-filter"
                  className="form-select !py-0 !w-fit"
                  value={pagination.discount_type}
                  onChange={(e) => {
                    GetItems({ discount_type: (e.target as HTMLSelectElement).value });
                  }}
                >
                  <option
                    disabled
                    value={''}
                  >
                    Discount type
                  </option>
                  <option value="">All</option>
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </th>
              <th className=" font-medium p-4  text-slate-600 text-start">Start date</th>
              <th className=" font-medium p-4  text-slate-600 text-start">End date</th>
              <th className=" font-medium p-4 text-slate-600 text-start">
                <select
                  name="status-filter"
                  id="status-filter"
                  className="form-select !py-0 !w-fit"
                  value={pagination.discount_type}
                  onChange={(e) => {
                    GetItems({ discount_type: (e.target as HTMLSelectElement).value });
                  }}
                >
                  <option
                    disabled
                    defaultValue={''}
                  >
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </th>
              <th className=" font-medium p-4  text-slate-600 text-start">Latest update</th>
              <th className=" font-medium p-4  text-slate-600 text-start">Actions </th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                const [deleteConfirm, setDeleteConfirm] = React.useState<boolean>(false);
                return (
                  <tr
                    className="divide-x divide-gray-200"
                    key={index}
                  >
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      {item.name || item.alt_name || '-'}
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <button
                        className="btn-with-icon outline-btn max-w-max"
                        onClick={() => CopyCode(item.code)}
                      >
                        <Icon
                          icon="fluent:document-copy-48-regular"
                          width={18}
                        />
                        <span>{item.code || '-'}</span>
                      </button>
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      {item.discount_type || '-'}
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <SharedTime date={item.start_date} />
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <SharedTime date={item.end_date} />
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <DotStatus active={!!item.is_active} />
                    </td>

                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <div className="inline-flex gap-2">
                        {item?.coupon_owner !== 'admin' ? (
                          <>
                            <button
                              className="action-btn text-blue-600"
                              onClick={() => {
                                setEditItem(item);
                                setEditVisible(true);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="action-btn text-gray-600"
                              onClick={() => ChangeStatus(item.id)}
                            >
                              Change status
                            </button>
                            <button
                              className="action-btn text-red-500"
                              onClick={() => setDeleteConfirm(true)}
                            >
                              Remove
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn-with-icon outline-btn text-neutral-600"
                            onClick={() => {
                              setSubscribeItem({
                                center_ids: item?.centers?.map((e: any) => e.id),
                                coupon_id: item.id
                              });
                              setSubscribeVisible(true);
                            }}
                          >
                            Subscribe
                          </button>
                        )}
                      </div>
                    </td>
                    <ConfirmAlert
                      visible={deleteConfirm}
                      requestClose={function (): void {
                        setDeleteConfirm(false);
                      }}
                      handleConfirm={function (): void {
                        DeleteItem(item.id);
                        setDeleteConfirm(false);
                      }}
                    />
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="Coupons table"
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <div className="flex items-center justify-between flex-wrap">
          <div></div>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button
              className="btn-with-icon !text-gray-600 shrink-0"
              onClick={() => setVisible(true)}
            >
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>New coupon</span>
            </button>

            {/* <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button> */}
          </div>
        </div>
        {MEMO_TABLE}
      </div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title="New coupon"
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
          centers={centers}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update coupon"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={{ ...editItem, center_ids: editItem?.centers?.map((e: any) => e.id) }}
          centers={centers}
        />
      </Modal>
      <Modal
        visible={subscribeVisible}
        handleClose={() => {
          setSubscribeItem({});
          setSubscribeVisible(false);
        }}
        title="Subscribe coupon"
      >
        <SubscribeForm
          closeModal={setSubscribeVisible}
          reFetching={GetItems}
          schema={subscribeItem}
          centers={centers}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  code: string | undefined;
  discount_type: string | undefined;
  discount_value: string | undefined;
  min_amount: string | undefined;
  max_amount: string | undefined;
  start_date: string | undefined;
  end_date: string | undefined;
  owner_per: string | undefined;
  user_limit: string | undefined;
  coupon_limit: string | undefined;
  center_ids: any[] | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching,
  centers
}: {
  closeModal: any;
  reFetching: any;
  centers: any[];
}): JSX.Element => {
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    code: undefined,
    discount_type: 'fixed',
    discount_value: undefined,
    min_amount: undefined,
    max_amount: undefined,
    start_date: undefined,
    end_date: undefined,
    owner_per: undefined,
    user_limit: undefined,
    coupon_limit: undefined,
    center_ids: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('coupons/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={errors}
        />
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.name}
            onChange={(e) => handleChange('name', e)}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">Alt name</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.alt_name}
            onChange={(e) => handleChange('alt_name', e)}
          />
          {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Code</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.code}
          onChange={(e) => handleChange('code', e)}
        />
        {errors?.code ? <span className="form-error">{errors?.code}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Centers</label>
        <Select
          type={'multi'}
          options={centers}
          value={formik.values.center_ids}
          onSelect={function (value: any): any {
            return formik.setFieldValue('center_ids', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />

        {errors?.center_ids ? <span className="form-error">{errors?.center_ids}</span> : null}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Discount type</label>
          <select
            name="discount-type-add"
            id="discount-type-add"
            value={formik.values.discount_type}
            onChange={(e) => handleChange('alt_name', e)}
            className="form-select form-outline"
          >
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>

          {errors?.dicount_type ? <span className="form-error">{errors?.dicount_type}</span> : null}
        </div>

        <div className="form-group">
          <label className="form-label">Discount value</label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.discount_value}
            onChange={(e) => handleChange('discount_value', e)}
          />
          {errors?.discount_value ? (
            <span className="form-error">{errors?.discount_value}</span>
          ) : null}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Min amount</label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.min_amount}
            onChange={(e) => handleChange('min_amount', e)}
          />
          {errors?.min_amount ? <span className="form-error">{errors?.min_amount}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">Max discount</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.max_discount}
            onChange={(e) => handleChange('max_discount', e)}
          />
          {errors?.max_discount ? <span className="form-error">{errors?.max_discount}</span> : null}
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Owner percentage</label>
        <input
          type="number"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.owner_per}
          onChange={(e) => handleChange('owner_per', e)}
        />
        {errors?.owner_per ? <span className="form-error">{errors?.owner_per}</span> : null}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Coupon limit</label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.coupon_limit}
            onChange={(e) => handleChange('coupon_limit', e)}
          />
          {errors?.coupon_limit ? <span className="form-error">{errors?.coupon_limit}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">User limit</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.user_limit}
            onChange={(e) => handleChange('user_limit', e)}
          />
          {errors?.user_limit ? <span className="form-error">{errors?.user_limit}</span> : null}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Start date</label>
          <input
            type="date"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.start_date || ''}
            onChange={(e) => handleChange('start_date', e)}
          />
          {errors?.start_date ? <span className="form-error">{errors?.start_date}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">End date</label>
          <input
            type="date"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.end_date || ''}
            min={formik.values.start_date}
            onChange={(e) => handleChange('end_date', e)}
          />
          {errors?.end_date ? <span className="form-error">{errors?.end_date}</span> : null}
        </div>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema,
  centers
}: {
  closeModal: any;
  reFetching: any;
  centers: any[];
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('coupons/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={errors}
        />
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.name}
            onChange={(e) => handleChange('name', e)}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">Alt name</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.alt_name}
            onChange={(e) => handleChange('alt_name', e)}
          />
          {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Code</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.code}
          onChange={(e) => handleChange('code', e)}
        />
        {errors?.code ? <span className="form-error">{errors?.code}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Centers</label>
        <Select
          type={'multi'}
          options={centers}
          value={formik.values.center_ids}
          onSelect={function (value: any): void {
            console.log(value);
            formik.setFieldValue('center_ids', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />

        {errors?.center_ids ? <span className="form-error">{errors?.center_ids}</span> : null}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Discount type</label>
          <select
            name="discount-type-add"
            id="discount-type-add"
            value={formik.values.discount_type}
            onChange={(e) => handleChange('alt_name', e)}
            className="form-select form-outline"
          >
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>

          {errors?.discount_type ? (
            <span className="form-error">{errors?.discount_type}</span>
          ) : null}
        </div>
        <div className="form-group">
          <label className="form-label">Discount value</label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.discount_value}
            onChange={(e) => handleChange('discount_value', e)}
          />
          {errors?.discount_value ? (
            <span className="form-error">{errors?.discount_value}</span>
          ) : null}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Min amount</label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.min_amount}
            onChange={(e) => handleChange('min_amount', e)}
          />
          {errors?.min_amount ? <span className="form-error">{errors?.min_amount}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">Max discount</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.max_discount}
            onChange={(e) => handleChange('max_discount', e)}
          />
          {errors?.max_discount ? <span className="form-error">{errors?.max_discount}</span> : null}
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Owner percentage</label>
        <input
          type="number"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.owner_per}
          onChange={(e) => handleChange('owner_per', e)}
        />
        {errors?.owner_per ? <span className="form-error">{errors?.owner_per}</span> : null}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Coupon limit</label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.coupon_limit}
            onChange={(e) => handleChange('coupon_limit', e)}
          />
          {errors?.coupon_limit ? <span className="form-error">{errors?.coupon_limit}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">User limit</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.user_limit}
            onChange={(e) => handleChange('user_limit', e)}
          />
          {errors?.user_limit ? <span className="form-error">{errors?.user_limit}</span> : null}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">Start date</label>
          <input
            type="date"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.start_date || ''}
            onChange={(e) => handleChange('start_date', e)}
          />
          {errors?.start_date ? <span className="form-error">{errors?.start_date}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">End date</label>
          <input
            type="date"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.end_date || ''}
            min={formik.values.start_date}
            onChange={(e) => handleChange('end_date', e)}
          />
          {errors?.end_date ? <span className="form-error">{errors?.end_date}</span> : null}
        </div>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

interface SubscribeFormInterface {
  coupon_id: string | undefined;
  center_ids: any[] | undefined;
}

const SubscribeForm = ({
  closeModal,
  reFetching,
  centers,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: any;
  centers: any[];
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<SubscribeFormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('coupons/subscribe', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={errors}
        />
      ) : null}

      <ul className="divide-y divide-neutral-200">
        {centers.map((center: any, index: number) => (
          <DisplayCenterOption
            center={center}
            values={formik.values}
            onChange={formik.handleChange}
            index={index}
          />
        ))}
      </ul>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

function DisplayCenterOption({
  center,
  values,
  onChange,
  index
}: {
  center: any;
  values: any;
  onChange: any;
  index: number;
}) {
  const isChecked = useMemo(() => {
    return values.center_ids?.includes(center.id?.toString());
  }, [values.center_ids]);

  return (
    <li className="py-2">
      <div className="inline-flex gap-3 items-center">
        <input
          type="checkbox"
          name={'center_ids'}
          id={'center-subscribe-item-' + center.id}
          className="form-checkbox shrink-0 peer"
          value={center.id}
          checked={isChecked}
          onChange={onChange}
        />
        <label
          htmlFor={'center-subscribe-item-' + center.id}
          className="text-sm font-medium text-neutral-600 peer-checked:line-through peer-checked:italic cursor-pointer"
        >
          {center.name}
        </label>
      </div>
    </li>
  );
}

export default Coupons;
