import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../components/shared/MiniDashboardSingleCard';

const Home: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [cards, setCards] = useState<ItemInterface[]>([
    {
      icon: 'ion:wallet-outline',
      color: 'text-teal-500',
      title: t('pages.incomes'),
      key: 'income',
      value: 0
    },

    {
      icon: 'basil:invoice-outline',
      color: 'text-sky-500',
      title: t('pages.bookings'),
      key: 'bookings_count',
      value: 0
    },
    {
      icon: 'ri:hospital-line',
      color: 'text-sky-500',
      title: t('pages.centers'),
      key: 'centers_count',
      value: 0
    },
    {
      icon: 'fa6-solid:user-doctor',
      color: 'text-teal-500',
      title: t('pages.doctors'),
      key: 'doctors_count',
      value: 0
    },

    {
      icon: 'mdi:patient-outline',
      color: 'text-teal-500',
      title: t('pages.patients'),
      key: 'patients_count',
      value: 0
    }
  ]);
  const [bookings, setBookings] = useState<any[]>([]);

  useEffect(() => {
    getDashboard();
  }, []);

  function getDashboard() {
    prepareRequest(
      {
        url: 'dashboard'
      },
      (data, error) => {
        const summary = data.result?.summary || [];
        setCards((items: ItemInterface[]) => {
          return items.map((item) => {
            if (Object.getPrototypeOf.call(summary, item.key)) {
              const value = summary[item.key as string];
              item.value = value;
              return item;
            }
            return item;
          });
        });
        setBookings(() => data.result?.recent_bookings || []);
      }
    );
  }

  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <div className="grid grid-wrapper gap-4">
          {cards.map((item: ItemInterface) => (
            <MiniDashboardSingleCard
              key={item.key}
              item={item}
            />
          ))}
        </div>
        <p className="text-sm text-gray-600">{t('recent-bookings')}</p>
        <Table
          RenderHead={() => (
            <tr>
              <th>#</th>
              <th>{t('form.doctor')}</th>
              <th>{t('form.patient')}</th>
              <th>{t('form.advance-payment')}</th>
              <th>{t('form.price')}</th>
              <th>{t('form.booking-time')}</th>
            </tr>
          )}
          RenderBody={() => (
            <>
              {bookings.map((item: any) => (
                <tr>
                  <td>
                    <Link
                      to={'/bookings/' + item.id}
                      className="text-blue-600"
                    >
                      {item.id}
                    </Link>
                  </td>
                  <td>
                    <p>{item.doctor?.name}</p>
                    <p>{item.doctor?.mobile}</p>
                  </td>
                  <td>
                    <p>{item.patient?.name}</p>
                    <p>{item.patient?.mobile}</p>
                  </td>
                  <td>{CurrencyFormatter(item.advance_payment || 0)}</td>
                  <td>{CurrencyFormatter(item.total || 0)}</td>

                  <td>{item.booking_time}</td>
                </tr>
              ))}
            </>
          )}
          isEmpty={!bookings.length}
        />
      </div>
    </React.Fragment>
  );
};

export default Home;
