import { Tab } from '@headlessui/react';
import { map } from 'lodash';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import DisplayGallery from 'src/components/shared/DisplayGallery';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Table from 'src/components/shared/tables/Table';
import DisplayDoctorServices from 'src/components/shared/users/DisplayDoctorServices';
import DisplayWorkHour from 'src/components/shared/users/DisplayWorkHour';
import UpdateDoctorForm from 'src/components/shared/users/UpdateDoctorForm';
import prepareRequest from 'src/helper/prepareRequest';
import { setTitle } from 'src/reducers/root';

const tabs = ['overflow', 'form.images', 'services', 'working-hours'];
const days = Array.from({ length: 7 })
  .fill(0)
  .map((_, i: number) => {
    const firstDate = moment().startOf('week').add(-1, 'days');
    const day = firstDate.clone().add(i, 'days');
    return { id: i + 1, day: day.format('dddd') };
  });

export default function _id() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [doctor, setDoctor] = useState<any>({});
  const [centers, setCenters] = useState<any[]>([]);
  const [workingHours, setWorkingHours] = useState<any[]>([]);
  const [center, setCenter] = useState<string>('');
  const [services, setServices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getDoctor(), getCenters(), getWorkingHours()]).finally(() => setIsLoading(false));
  }, []);

  function getDoctor() {
    prepareRequest(
      {
        url: 'users/' + id,
        method: 'get'
      },
      (data) => {
        let result = data.result?.user || {};
        result.center_ids = result.centers?.map((e: any) => e.id);
        result.category_ids = result.categories?.map((e: any) => e.id);

        setDoctor(() => result);
        getServices();
        dispatch(setTitle(result.name));
      }
    );
  }

  function getServices() {
    const category_id = map(doctor?.categories, 'id');
    prepareRequest(
      {
        url: 'services',
        params: { is_active: 1, category_id },
        method: 'get'
      },
      (data) => setServices(() => data.result?.services || [])
    );
  }

  function getCenters() {
    prepareRequest(
      {
        url: 'centers',
        params: { is_active: 1 },
        method: 'get'
      },
      (data) => setCenters(() => data.result?.centers || [])
    );
  }

  function getWorkingHours() {
    prepareRequest(
      {
        url: 'working_hours',
        params: { doctor_id: id },
        method: 'get'
      },
      (data) => setWorkingHours(() => data.result?.working_hours || [])
    );
  }

  const currentDay = useCallback(
    (item: any) => {
      const existed = workingHours.find((hour) => hour.day == item.id);
      return existed;
    },
    [workingHours]
  );

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="relative">
      <div className="w-full py-20 px-6 bg-primary">
        <p className="text-center text-2xl font-bold text-white">
          {t('edit')} - ( {doctor.name} )
        </p>
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 p-6">
        <div className="w-full flex-1 lg:max-w-sm">
          <Card className="!-mt-20">
            <ul className="divide-y divide-gray-200">
              <ItemList className="py-3">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500 font-medium">{t('form.name')}</p>
                  <p>{doctor.name || '-'}</p>
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500 font-medium truncate">{t('form.email')}</p>
                  <p>{doctor.email || '-'}</p>
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500 font-medium truncate">{t('form.mobile')}</p>
                  <p>{doctor.mobile || '-'}</p>
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500 font-medium">{t('join-date')}</p>
                  <p>{moment(doctor.created_at).fromNow()}</p>
                </div>
              </ItemList>
            </ul>
          </Card>
        </div>
        <div className="w-full flex-1">
          <Tab.Group>
            <Tab.List className="flex items-center whitespace-nowrap overflow-x-auto">
              {tabs.map((tab, index) => (
                <Tab
                  as={Fragment}
                  key={index}
                >
                  {({ selected }) => (
                    <button
                      className={`btn-with-icon transition ${
                        selected ? 'bg-primary' : '!text-gray-600'
                      }`}
                    >
                      <span>{t(tab)}</span>
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="pt-6">
                  <UpdateDoctorForm
                    item={doctor}
                    refetch={getDoctor}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="pt-6">
                  <DisplayGallery
                    items={doctor.images || []}
                    pathname="users/delete_image"
                    refetch={getDoctor}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="pt-6">
                  <DisplayDoctorServices
                    services={services}
                    refetch={getServices}
                    assigned_services={doctor.services || []}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="pt-6 space-y-4">
                  <div className="form-group">
                    <p className="form-label">{t('form.center')}</p>
                    <select
                      className="form-select form-outline"
                      name="center_id"
                      defaultValue={center}
                      onChange={(value) => setCenter(() => value.target.value)}
                    >
                      <option value="">-- {t('select')} --</option>
                      {centers.map((item: any) => (
                        <option
                          value={item.id}
                          key={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Table
                    RenderHead={() => (
                      <tr>
                        <th></th>
                        <th>{t('form.day')}</th>
                        <th>{t('from')}</th>
                        <th>{t('to')}</th>
                        <th></th>
                      </tr>
                    )}
                    RenderBody={() => (
                      <>
                        {days.map((item, index) => (
                          <DisplayWorkHour
                            item={item}
                            key={index}
                            center={center}
                            shift_from={currentDay(item)?.shift_from}
                            shift_to={currentDay(item)?.shift_to}
                            refetch={getWorkingHours}
                          />
                        ))}
                      </>
                    )}
                  />
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
