import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/shared/Button';
import Switcher from 'src/components/shared/Switcher';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';

export default function Features() {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['is_active'],
    queryFn: () =>
      axiosInstance.get('features', { params: { is_active: 1 } }).then(({ data }) => {
        return data.result?.features || [];
      })
  });

  const {
    formik: { setFieldValue, handleSubmit, values }
  } = useForm({
    initialValues: { category_ids: [] },
    submitHandler(values, formikHelpers) {
      console.log(values);
    }
  });
  return (
    <form
      className="p-6 space-y-4"
      onSubmit={handleSubmit}
    >
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('actions')}</th>
            <th>{t('form.name')}</th>
            <th>{t('form.alt-name')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <Switcher
                    checked={values.category_ids.includes(item.id)}
                    onChange={(checked) =>
                      setFieldValue(
                        'category_ids',
                        checked
                          ? [...values.category_ids, item.id]
                          : values.category_ids.filter((id: any) => id !== item.id)
                      )
                    }
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.alt_name}</td>
              </tr>
            ))}
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
      />
      <Button
        variant="primary"
        type="submit"
      >
        <span>{t('save-changes')}</span>
      </Button>
    </form>
  );
}
