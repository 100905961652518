import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Button from 'src/components/shared/Button';
import Mobile from 'src/components/shared/Mobile';
import Select from 'src/components/shared/Select';
import prepareRequest from 'src/helper/prepareRequest';
import Swal from 'sweetalert2';

interface AuthInterface {
  mobile?: string;
  email?: string;
  password?: string;
  name?: string;
  center_name?: string;
  region_id?: string;
  city_id?: string;
  district_id?: string;
}

const FormBody: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const globalValues = {
    name: undefined,
    center_name: undefined,
    email: undefined,
    mobile: undefined,
    password: undefined,
    region_id: undefined,
    city_id: undefined,
    district_id: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [districts, setDistricts] = React.useState<any[]>([]);

  const { handleSubmit, setFieldValue, values, handleChange } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        {
          url: 'register',
          method: 'post',
          data: {
            ...values,
            mobile: values.mobile?.replace(/\s/gi, '')
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          helper.resetForm();

          Swal.fire({
            icon: 'success',
            title: t('email-congrats'),
            text: t('email-congrats-content').replace(
              '{{EMAIL}}',
              (str: string) => (values.email || str) as string
            ),
            confirmButtonText: t('close')
          }).then(({ isConfirmed }) => {
            if (isConfirmed) navigate('/account/login', { replace: true });
          });
        }
      ).finally(() => setDisabled(false));
    }
  });

  React.useEffect(() => {
    getRegions();
  }, []);

  function getRegions() {
    prepareRequest({ url: 'regions', params: { is_active: 1 } }, (data) => {
      if (data) setRegions(() => data.result?.regions || []);
    });
  }

  function getCities(q?: string, region_id?: any) {
    prepareRequest(
      {
        url: 'cities',
        params: { is_active: 1, page: 1, search_key: q, region_id }
      },
      (data) => {
        if (data) setCities(() => data.result?.cities?.data || []);
      }
    );
  }

  function getDistricts(q?: string, region_id?: any, city_id?: any) {
    prepareRequest(
      {
        url: 'districts',
        params: {
          is_active: 1,
          page: 1,
          search_key: q,
          region_id,
          city_id
        }
      },
      (data) => {
        if (data) setDistricts(() => data.result?.districts?.data || []);
      }
    );
  }

  return (
    <Fragment>
      <form
        className="space-y-4"
        onSubmit={(e) => {
          e.preventDefault();
          console.log('submission from form');
          handleSubmit();
        }}
      >
        <div className="mb-6 space-y-4 text-center">
          <p className="text-3xl font-bold">{t('create-new-account')}</p>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          <div className="form-group col-span-full">
            <label
              htmlFor="name"
              className="form-label"
            >
              {t('form.full-name')}
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Mohammed abduallah"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('name', target.value)
              }
            />
            {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
          </div>

          <div className="form-group">
            <label
              htmlFor="email"
              className="form-label"
            >
              {t('form.email')}
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('email', target.value)
              }
            />
            {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
          </div>
          <div className="form-group">
            <label
              htmlFor="mobile"
              className="form-label"
            >
              {t('form.mobile')}
            </label>
            <Mobile
              name="mobile"
              id="mobile"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('mobile', target.value)
              }
            />
            {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
          </div>

          <div className="form-group col-span-full">
            <label
              htmlFor="center_name"
              className="form-label"
            >
              {t('form.center-name')}
            </label>
            <input
              type="text"
              name="center_name"
              id="center_name"
              placeholder=".."
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('center_name', target.value)
              }
            />
            {errors?.center_name ? <span className="form-error">{errors?.center_name}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.region')}</label>
            <Select
              type={'single'}
              options={regions}
              value={values.region_id}
              onSelect={function (value: any): void {
                setFieldValue('region_id', value);
                setFieldValue('city_id', undefined);
                setFieldValue('district_id', undefined);
                getCities(undefined, value);
              }}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.region_id ? <span className="form-error">{errors?.region_id}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.city')}</label>
            <Select
              type={'single'}
              options={cities}
              value={values.city_id}
              onSelect={function (value: any): void {
                setFieldValue('city_id', value);
                setFieldValue('district_id', undefined);
                getDistricts(undefined, values.region_id, value);
              }}
              onSearchChange={(v) => getCities(v, values.region_id)}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.city_id ? <span className="form-error">{errors?.city_id}</span> : null}
          </div>
          <div className="form-group col-span-full">
            <label className="form-label">{t('form.district')}</label>
            <Select
              type={'single'}
              options={districts}
              value={values.district_id}
              onSelect={function (value: any): void {
                setFieldValue('district_id', value);
              }}
              onSearchChange={(v) => getDistricts(v, values.region_id, values.city_id)}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.district_id ? <span className="form-error">{errors?.district_id}</span> : null}
          </div>
        </div>

        <div className="!mt-8 space-y-2">
          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('create-new-account')}</span>
          </Button>
          <p className="flex items-center gap-4 !my-4">
            <span className="flex-1 h-px bg-gray-200"></span>
            <span className="text-sm font-medium text-gray-600 block">{t('or')}</span>
            <span className="flex-1 h-px bg-gray-200"></span>
          </p>
          <p className="text-gray-600 text-center text-sm">
            {t('have-account')}{' '}
            <Link
              className="font-medium text-primary hover:underline transition"
              to="/account/login"
            >
              <span>{t('sign-in')}</span>
            </Link>
          </p>
        </div>
      </form>
    </Fragment>
  );
};

const Register: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="max-w-screen-lg m-auto w-full">
        <FormBody />
      </div>
    </AuthWrapper>
  );
};

export default Register;
