import { Icon } from '@iconify/react';
import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';

export default function DisplayUserCard({
  user,
  onDelete
}: {
  user: any;
  onDelete: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}) {
  const { t } = useTranslation();
  return (
    <Card className="!p-4">
      <div className="flex items-center justify-between gap-4">
        <div className="flex-1 text-start">
          <div className="flex justify-between gap-3">
            <p className="text-sm">{user.name}</p>
            {/* {user.user_type === 'doctor' ? (
              <p className="text-sm">{CurrencyFormatter(user.price || 0)}</p>
            ) : null} */}
          </div>
          <p className="text-sm text-gray-500">{user.mobile}</p>
          {/* {user.advance_payment_value ? (
            <p className="text-sm text-red-500">
              {t('form.advance-payment')}: {CurrencyFormatter(user.advance_payment_value || 0)}
            </p>
          ) : null} */}
        </div>
        <button
          type="button"
          className="btn-with-icon !bg-red-500 !p-1.5 !rounded-full"
          onClick={onDelete}
        >
          <Icon
            icon="material-symbols:close"
            width="16"
            height="16"
          />
        </button>
      </div>
    </Card>
  );
}
