import React, { useCallback, useMemo } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import useForm from 'src/hooks/useForm';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import Table from 'src/components/shared/tables/Table';
import SharedTime from 'src/components/shared/SharedTime';

import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import { useTranslation } from 'react-i18next';
import ItemList from 'src/components/shared/ItemList';
// import ExportOptions from 'src/components/shared/ExportOptions';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';

export type VariableType = 'bonus' | 'deduction';

export default function Salaries() {
  const { t } = useTranslation();
  let rerender: boolean = true;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [viewVisible, setViewVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });

  React.useEffect(() => {
    if (rerender) {
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('salaries', {
        params: {
          ...paginate
        }
      });
      const salaries = data?.result?.salaries || data?.result?.salaries_query;
      const { data: items, pagination: responsePaginate } = salaries;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const changeSalary = useCallback((item: any, type: VariableType) => {
    const { id, name } = item;
    setEditItem({
      employee_id: id,
      name,
      type
    });
    setVisible(true);
  }, []);

  const viewDetails = useCallback((item: any, type: VariableType) => {
    const { id, name, bonuses, deductions } = item;
    const data = type === 'bonus' ? bonuses : deductions;
    if (!data || !data.length) return generateAlert(t('no-data'), 'info');

    setEditItem({
      name,
      type,
      data
    });
    setViewVisible(true);
  }, []);

  const saveSalary = () => {
    setDisabled(true);
    prepareRequest(
      {
        url: 'salaries/save',
        method: 'post'
      },
      (data, error) => {
        if (error) return generateAlert(error, 'error');
        generateAlert('success', data.message);
        GetItems();
      }
    ).finally(() => setDisabled(false));
  };

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('form.name')}</th>
              <th>{t('salary')}</th>
              <th>{t('target')}</th>
              <th>{t('deduction')}</th>
              <th>{t('bonus')}</th>
              <th>{t('total')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.name || '-'} <br /> {item.mobile}
                    </td>
                    <td>{CurrencyFormatter(item.salary || 0)}</td>
                    <td>{CurrencyFormatter(item.target_amount || 0)}</td>
                    <td>
                      <div className="btn-with-icon !bg-gray-100 !text-gray-600 w-fit">
                        <button
                          type="button"
                          className="btn-with-icon !rounded-full bg-blue-500 !text-white !p-1"
                          onClick={() => viewDetails(item, 'deduction')}
                        >
                          <Icon
                            icon="majesticons:eye-line"
                            width="15"
                          />
                        </button>
                        <span>|</span>
                        <span>{CurrencyFormatter(item.total_deductions || 0)}</span>
                        <span>|</span>
                        <button
                          type="button"
                          className="btn-with-icon !rounded-full bg-blue-500 !text-white !p-1"
                          onClick={() => changeSalary(item, 'deduction')}
                        >
                          <Icon
                            icon="ic:baseline-plus"
                            width="15"
                          />
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="btn-with-icon !bg-gray-100 !text-gray-600 w-fit">
                        <button
                          type="button"
                          className="btn-with-icon !rounded-full bg-blue-500 !text-white !p-1"
                          onClick={() => viewDetails(item, 'bonus')}
                        >
                          <Icon
                            icon="majesticons:eye-line"
                            width="15"
                          />
                        </button>
                        <span>|</span>
                        <span>{CurrencyFormatter(item.total_bonuses || 0)}</span>
                        <span>|</span>
                        <button
                          type="button"
                          className="btn-with-icon !rounded-full bg-blue-500 !text-white !p-1"
                          onClick={() => changeSalary(item, 'bonus')}
                        >
                          <Icon
                            icon="ic:baseline-plus"
                            width="15"
                          />
                        </button>
                      </div>
                    </td>
                    <td>{CurrencyFormatter(item.total_salary || 0)}</td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              {/* <ExportOptions
                excelPathname="salaries/export_excel"
                cvsPathname="salaries/export_csv"
                pathname="salaries/send_excel"
                pdfPathname="salaries/export_pdf"
              /> */}
              <ButtonWithCallback
                className="btn-with-icon !bg-blue-500"
                disabled={disabled}
                options={{
                  title: t('are-you-sure'),
                  confirmButtonText: t('yes'),
                  cancelButtonText: t('close'),
                  showCancelButton: true,
                  icon: 'info'
                }}
                callback={() => saveSalary()}
              >
                <Icon
                  icon="fluent:save-multiple-24-regular"
                  width="18"
                />
                <span>{t('save')}</span>
              </ButtonWithCallback>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => {
          setVisible(false);
          setEditItem({});
        }}
        title={[editItem.name || '-', '( ' + t(editItem.type) + ' )'].join(' ')}
      >
        <AddForm
          closeModal={setVisible}
          reFetching={GetItems}
          employee_id={editItem.employee_id}
          variable_type={editItem.type}
        />
      </Modal>
      <Modal
        visible={viewVisible}
        handleClose={() => {
          setViewVisible(false);
          setEditItem({});
        }}
        title={[editItem.name || '-', '( ' + t(editItem.type) + ' )'].join(' ')}
      >
        <DisplayVariables data={editItem.data} />
      </Modal>
    </React.Fragment>
  );
}

interface AddFormInterface {
  employee_id: string | undefined;
  variable_type: string | undefined;
  amount: number | undefined;
  notes: string | undefined;
}

function AddForm({ closeModal, reFetching, employee_id, variable_type }: any) {
  const { t } = useTranslation();

  const globalValues = {
    employee_id,
    variable_type,
    amount: undefined,
    notes: undefined
  } satisfies AddFormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<AddFormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  async function submitHandler(values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('salaries/add_variable', values);
      await reFetching();
      formik.resetForm();
      closeModal?.(false);
      generateAlert(data.message, 'success');
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          generateAlert('Something went wrong while creating..', 'error');
        }
        return;
      }
      generateAlert('Something went wrong while creating..', 'error');
    } finally {
      setDisabled(false);
    }
  }

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
    >
      <div className="form-group">
        <p className="form-label">{t('amount')}</p>
        <input
          type="number"
          autoComplete="off"
          placeholder="0.00 SAR"
          className="form-input form-outline"
          value={formik.values.amount}
          onChange={(e) => handleChange('amount', e)}
          step="any"
          min="0"
        />
        {errors?.amount ? <span className="form-error">{errors?.amount}</span> : null}
      </div>
      <div className="form-group">
        <p className="form-label">{t('notes')}</p>
        <textarea
          placeholder={t('placeholder')}
          className="form-input form-outline min-h-min"
          value={formik.values.notes}
          onChange={(e) => handleChange('notes', e)}
          maxLength={255}
        ></textarea>
        {errors?.notes ? <span className="form-error">{errors?.notes}</span> : null}
      </div>
      <div className="flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

function DisplayVariables({ data = [] }: any) {
  const getTotal = useMemo(() => {
    let total = 0;

    for (const item of data) {
      total += Number(item.amount || 0);
    }

    return total;
  }, [data]);
  return (
    <ul className="divide-y divide-gray-200">
      {data.map((item: any) => (
        <ItemList
          className="py-3"
          key={item.id}
        >
          <div className="flex items-start gap-4">
            <div className="space-y-2 flex-1">
              <div className="space-y-1">
                <p className="text-sm font-semibold text-gray-600">{item.user?.name || '-'}</p>
                {item.notes ? (
                  <p className="text-sm text-gray-600 py-2 px-3 max-w-fit bg-gray-100">
                    {item.notes}
                  </p>
                ) : null}
              </div>
              <SharedTime date={item.created_at} />
            </div>
            <p className="font-semibold text-gray-800">{CurrencyFormatter(item.amount || 0)}</p>
          </div>
        </ItemList>
      ))}

      <ItemList className="py-3">
        <div className="flex items-center gap-4">
          <div className="space-y-0.5 flex-1"></div>
          <p className="font-semibold text-gray-800">{CurrencyFormatter(getTotal)}</p>
        </div>
      </ItemList>
    </ul>
  );
}

