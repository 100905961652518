import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import Image from './Image';

export default function DisplayGallery({
  pathname,
  items,
  refetch
}: {
  pathname: string;
  items: any[];
  refetch: any;
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [gallery, setGallery] = useState<any[]>([]);

  useEffect(() => {
    setGallery(items);
  }, [items]);

  function deleteCurrentItem(id: string | number) {
    setDisabled(true);
    prepareRequest(
      {
        url: pathname + '/' + id,
        method: 'post'
      },
      (data) => {
        generateAlert(data.message, 'success');
        setGallery(() => {
          return gallery.filter((item) => item.id !== id);
        });
        refetch?.();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <div>
      <ul className="grid grid-wrapper gap-4">
        {gallery.map((item) => (
          <li
            className="relative"
            key={item.id}
          >
            <Image
              src={item.image}
              alt={item.image}
              width="350"
              height="250"
              className="w-full rounded-lg h-full max-h-[250px] object-contain object-center bg-gray-100"
            />
            <button
              type="button"
              className="w-8 h-8 rounded-full flex items-center justify-center text-white bg-red-700 border-2 border-white absolute -m-3 top-0"
              disabled={disabled}
              onClick={() => deleteCurrentItem(item.id)}
            >
              <Icon
                icon="material-symbols:close"
                width="1.5rem"
                height="1.5rem"
              />
            </button>
          </li>
        ))}
        {!gallery.length && (
          <li className="col-span-full">
            <p className="text-center text-gray-600">{t('no-data')}</p>
          </li>
        )}
      </ul>
    </div>
  );
}
